import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import SearchIcon from "@material-ui/icons/Search"
import ReceiptIcon from "@material-ui/icons/Receipt"
import StarIcon from "@material-ui/icons/StarRounded"
import FilterIcon from "@material-ui/icons/Filter"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    }
}))

export default function MainMenu() {
    const classes = useStyles()
    const history = useHistory()
    return (
        <div className={classes.root}>
            <List component="nav" aria-label="Main mailbox folders">
                <ListItem button onClick={() => history.push('/')}>
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Search" />
                </ListItem>
                <ListItem button onClick={() => history.push('/goldstars')}>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gold Stars" />
                </ListItem>
                <ListItem button onClick={() => history.push('/searches')}>
                    <ListItemIcon>
                        <FilterIcon />
                    </ListItemIcon>
                    <ListItemText primary="Saved Searches" />
                </ListItem>
                <ListItem button onClick={() => history.push('/receipts')}>
                    <ListItemIcon>
                        <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Receipts" />
                </ListItem>
            </List>
        </div>
    )
}
