import React from 'react'

import { Avatar } from '@material-ui/core'
import iconWalmart from '../../icons/icon_walmart.png'
import iconWholesaleClub from '../../icons/icon_wholesale-club.png'
import iconSuperstore from '../../icons/icon_superstore.png'
import iconSaveOnFoods from '../../icons/icon_save-on-foods.png'
import iconThriftyFoods from '../../icons/icon_thrifty-foods.png'
import iconTheMarketStores from '../../icons/icon_the-market-stores.png'
import iconQualityFoods from '../../icons/icon_quality-foods.png'
import iconLondonDrugs from '../../icons/icon_london-drugs.png'
import iconCountryGrocer from '../../icons/icon_country-grocer.png'
import iconBulkBarn from '../../icons/icon_bulk-barn.png'
import { Product } from './types'

function getStoreIcon(store: Product["store"]) {
    switch (store) {
        case "WALMART":
            return iconWalmart
        case "LONDON_DRUGS":
            return iconLondonDrugs
        case "QUALITY_FOODS":
            return iconQualityFoods
        case "THE_MARKETS":
            return iconTheMarketStores
        case "WHOLESALE_CLUB":
            return iconWholesaleClub
        case "SAVE_ON_FOODS":
            return iconSaveOnFoods
        case "THRIFTY_FOODS":
            return iconThriftyFoods
        case "COUNTRY_GROCER":
            return iconCountryGrocer
        case "SUPERSTORE":
            return iconSuperstore
        case "BULKBARN":
            return iconBulkBarn
    }
}

export default function StoreAvatar(props: { store: Product["store"] }) {
    return <Avatar src={getStoreIcon(props.store)} />
}