import React from 'react'
import { Typography, makeStyles, createStyles, Theme, Box, Switch, TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ControlPanelProps, ControlPanel } from './ControlPanel'
import { Maybe, Unit } from '@grocery/graphql/dist/client/gen-types'
import { ProductAlert } from './types'

const useStyles = makeStyles((theme: Theme) => createStyles({
    twoColumn: {
        display: 'grid',
        marginLeft: theme.spacing(2),
        gridTemplateColumns: '1fr 1fr',
    },
    picker: {
        height: '100%',
        width: '100%',
    },
    title: {
        fontSize: theme.typography.h4.fontSize,
        marginBottom: theme.spacing(2),
    }
}))

type AlertingControlPanelProps = {
    onDelete(): void,
    onChange: (alert: ProductAlert) => void,
    unit?: Maybe<Unit>,
    alert: ProductAlert | undefined
} & ControlPanelProps

export const AlertingControlPanel: React.FC<AlertingControlPanelProps> = props => {
    const classes = useStyles({})
    // const [price, setPrice] = useState(props.alert?.alertValueThreshold ?? 1)
    const price = props.alert?.alertValueThreshold ?? 1
    // const handleChange = useRef(debounce((settings: ProductAlert) => props.onChange(settings), 500))
    const alertEnabled = props.alert !== undefined

    const handleEnableToggle = (checked: boolean) => {
        if (checked) {
            props.onChange({ alertValueThreshold: price, silencedProductIDs: props.alert?.silencedProductIDs ?? [] })
        } else {
            props.onDelete()
        }
    }

    const handleValueChange = (value: number) => {
        // setPrice(value)
        if (props.alert) {
            props.onChange({ ...props.alert, alertValueThreshold: value })
            // handleChange.current({  })
        }
    }

    return <ControlPanel {...props} dirty={props.dirty && alertEnabled}>
        <Typography className={classes.title} variant="h2">Alerting</Typography>
        <Box className={classes.twoColumn}>
            <Typography>Alert me about products in this search</Typography>
            <Box style={{ marginLeft: 'auto' }}>
                <Switch
                    checked={alertEnabled}
                    onChange={(e, checked) => handleEnableToggle(checked)}
                    value="alertEnabled"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </Box>
            {alertEnabled && <>
                <TextField id="priceInput" label="Price per-unit" variant="outlined"
                    onChange={ev => handleValueChange(parseFloat(ev.currentTarget.value))}
                    InputProps={{
                        defaultValue: price?.toFixed(2),
                        type: 'number',
                        startAdornment: <InputAdornment position="start">
                            $
                    </InputAdornment>,
                        endAdornment: <InputAdornment position="end"> {props.unit} </InputAdornment>,
                    }} />

            </>}
        </Box>
    </ControlPanel>
}

export default AlertingControlPanel