import isEqual from 'lodash.isequal'
import {FilterConfig} from '../filter'

export interface FilterReducerState {
    loadedFilter?: FilterConfig
    changedFilter?: FilterConfig
    filterDirty: boolean
}

export type FilterReducerAction =
    | { type: 'filter.changed', filter: FilterConfig }
    | { type: 'filter.load', filter: FilterConfig }
    | { type: 'filter.reset' }

export const defaultState: FilterReducerState = {
    filterDirty: false
}

export const reducer = (state: FilterReducerState, action: FilterReducerAction): FilterReducerState => {
    console.log('action', action)
    console.dir(state)
    switch (action.type) {
        case 'filter.reset':
            return { ...state, changedFilter: state.loadedFilter, filterDirty: false }
        case 'filter.changed':
            return { ...state, changedFilter: action.filter, filterDirty: !isEqual(state.loadedFilter, action.filter) }
        case 'filter.load':
            return { ...state, loadedFilter: action.filter, filterDirty: false, changedFilter: action.filter }
        default:
            return state
    }
}