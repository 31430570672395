import React, { useState, useEffect } from 'react'
import { useApolloClient } from '../../hooks/useApolloClient'
import { RecentGoldQueryResult, RecentGoldQueryVariables } from '@grocery/graphql/dist/client/gen-types'
import { ExtractQueryResultType } from '../../lib/typeHelpers'
import { GetRecentGoldQuery } from '@grocery/graphql/dist/client/queries/GetRecentGold'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import { useHistory } from 'react-router'
import Page from '../page'

interface RecentGold {
    keywords: string
    ageDays: number
}

export default () => {
    const [recentGold, setRecentGold] = useState<RecentGold[]>([])
    const client = useApolloClient()
    const history = useHistory()

    useEffect(() => {
        async function doFetch() {
            const { data } = await client.query<ExtractQueryResultType<RecentGoldQueryResult>, RecentGoldQueryVariables>({ query: GetRecentGoldQuery, variables: { maxAgeDays: 5 }, fetchPolicy: 'no-cache' })
            setRecentGold(data.getRecentGold)
        }
        doFetch()
    }, [client, setRecentGold])



    return <Page loading={false} topBarContent={'Gold Stars'}>
        <List component="nav" aria-label="contacts" style={{ width: '100%' }}>
            {
                recentGold
                    .sort((a, b) => a.keywords.localeCompare(b.keywords))
                    .map(rg => <RecentGoldItem key={rg.keywords} onClick={() => history.push(`/search/${rg.keywords}`)} {...rg} />)
            }
        </List>
    </Page>
}

interface RecentGoldItemProps {
    keywords: string
    onClick: () => void
    ageDays: number
}

function RecentGoldItem({ keywords, onClick, ageDays }: RecentGoldItemProps) {
    const ageTxt = getAgeText(ageDays)
    return (<ListItem divider button>
        <ListItemText primary={keywords} secondary={'seen ' + ageTxt} onClick={onClick}></ListItemText>
    </ListItem>)
}

function getAgeText(ageDays: number) {
    switch (ageDays) {
        case 0:
            return "today"
        case 1:
            return "yesterday"
        default:
            return `${ageDays} days ago`
    }
}