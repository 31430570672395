import React from 'react'
import { useApolloClient } from '../../hooks/useApolloClient'
import { useSavedKeywordsQuery } from '@grocery/graphql/dist/client/gen-types'
import { useHistory } from 'react-router'
import { List, ListItem, ListItemText } from '@material-ui/core'
import Page from '../page'

export default () => {
    const client = useApolloClient()
    const history = useHistory()
    const { data, error, loading } = useSavedKeywordsQuery({
        client,
        variables: {}
    })

    return <Page loading={loading} error={error} topBarContent={'Saved Searches'}>
        <List component="nav" aria-label="contacts" style={{ width: '100%' }}>
            {
                (data?.savedKeywords ?? [])
                    .sort((a, b) => a.localeCompare(b))
                    .map(kw => (<ListItem divider key={kw} button onClick={() => history.push(`/search/${kw}`)}><ListItemText>{kw}</ListItemText></ListItem>))
            }
        </List>
    </Page>
}