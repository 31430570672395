import React from "react"
import Page from '../page'
import { useState, useEffect } from 'react'
import { useApolloClient } from '../../hooks/useApolloClient'
import { GetSearchListQueryResult, GetSearchListQueryVariables } from '@grocery/graphql/dist/client/gen-types'
import { ExtractQueryResultType } from '../../lib/typeHelpers'
import { SearchProductsQueryResult, SearchProductsQueryVariables } from '@grocery/graphql/dist/client/gen-types'
import { SearchProductsQuery } from '@grocery/graphql/dist/client/queries/ProductQuery'
import { GetSearchList } from '@grocery/graphql/dist/client/queries/GetSearchList'
import { useParams } from "react-router-dom"
import Paper from "@material-ui/core/Paper"
import LazyScrollableProductList from "../../components/LazyScrollableProductList"
import ProductListCard from "../../components/lists/product/ProductListCard"
import { ProductListItemProduct, resultsToProductList } from "../../transformers/flatten_store_product"
import { filterProducts } from "../../filter"

export default function(){
    const client = useApolloClient()
    const {name} = useParams<{ name: string }>()
    const [keywordList, setKeywordList] = useState<string[] | null>(null)
    const [searching, setSearching] = useState(false)
    const [results, setResults] = useState<ProductListItemProduct[]>([]);

    useEffect(() => {
        async function doFetch() {
            if(keywordList === null){
                const { data } = await client.query<ExtractQueryResultType<GetSearchListQueryResult>, GetSearchListQueryVariables>({ query: GetSearchList, variables: { name }, fetchPolicy: 'no-cache' })
                setKeywordList(data.searchList.keywordList)
            }
        }
        doFetch()
    }, [client, setKeywordList, name, keywordList])

    useEffect(() => {
        if(searching || keywordList === null){
            return
        }
        setSearching(true)
        async function doFetch() {
            for(const keywords of keywordList!){
                const { data } = await client.query<ExtractQueryResultType<SearchProductsQueryResult>, SearchProductsQueryVariables>({ query: SearchProductsQuery, variables: { keywords }, fetchPolicy: 'no-cache' })
                setResults(oldResults => {
                    return [...oldResults, ...filterProducts(resultsToProductList(data.search.storeResults).products, data.getFilter)]
                })
            }
        }
        doFetch()
    },[client,setSearching, searching, setResults, keywordList])

    useEffect(() => {
        const sortedProducts = results.sort((a, b) => (a.pricePerUnit.price ? a.pricePerUnit.price : 0) - (b.pricePerUnit.price ? b.pricePerUnit.price : 0))
        setResults(sortedProducts)
    }, [results, setResults])

    const renderProductRow = (index: number): [JSX.Element, string] => {
        // TODO: probs make this nicer, but should work
        let rowKey: string
        let element: JSX.Element
        
        element = <ProductListCard
            product={results[index]}
            isGoodPrice={false}
            onDelete={() => console.log("DELETE NOT IMPLEMENTED")}
        />
        rowKey = results[index].id
        return [element, rowKey]
    }

    return <Page loading={keywordList === null} topBarContent={`Search List - ${name}`}>
        {keywordList !== null && keywordList.map(s => <strong>{s}</strong>)}

        {/* Results List */}
        <Paper square={false} style={{ display: 'flex', flex: 1 }}>
            <LazyScrollableProductList
                renderRow={renderProductRow}
                rowHeightPx={76}
                numProducts={results.length}
            />
        </Paper>
    </Page>
}