import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { ValueType } from 'react-select'

interface CreatablePickerProps {
  setRef?: (ref: any) => void
  className?: string
  options: string[]
  defaultValue: string
  placeholder?: string
  onChange(value: string): void
}

interface PickerOption {
  value: string
  label: string
}

export default function CreatablePicker(props: CreatablePickerProps) {
  const options = props.options.map((o) => ({ value: o, label: o }))

  return (
    <CreatableSelect
      ref={(ref) => {
        props.setRef && props.setRef(ref)
      }}
      isClearable
      placeholder={props.placeholder}
      value={
        props.defaultValue
          ? { value: props.defaultValue, label: props.defaultValue }
          : undefined
      }
      onChange={(opt) => handleChange(opt, props.onChange)}
      options={options}
      styles={{
        control: (base) => ({
          ...base,
          height: '100%',
        }),
      }}
      className={props.className}
    />
  )
}

function handleChange(
  opt: ValueType<PickerOption>,
  cb: (value: string) => void,
) {
  if (Array.isArray(opt)) {
    cb(opt.map((o) => o.value).join(','))
  } else if (opt) {
    cb((opt as PickerOption).value)
  } else {
    cb('')
  }
}
