import React, { useState, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import AutoSuggestInput from './AutoSuggestInput'

interface SearchInputProps {
    disabled: boolean
    previousSearch: string | undefined
    onChange: (keywords: string) => void
    getSuggestions: (keywords: string) => string[]
}

export default function SearchInput(props: SearchInputProps) {
    const { onChange, getSuggestions, disabled, previousSearch } = props
    const [keywords, setKeywords] = useState({
        current: '',
        lastSubmitted: '',
    })

    useEffect(() => {
        const val = previousSearch || ''
        setKeywords({ current: val, lastSubmitted: val })
    }, [previousSearch])

    const submitSearch = () => {
        setKeywords({ ...keywords, lastSubmitted: keywords.current })
        onChange(keywords.current)
    }

    return <>
        <AutoSuggestInput disabled={disabled} suggestEnabled={keywords.current !== keywords.lastSubmitted} value={keywords.current} placeholder="Search..." getSuggestions={() => [...getSuggestions(keywords.current), keywords.current].filter((value, index, self) => self.indexOf(value) === index)} onChange={(value) => setKeywords({ ...keywords, current: value })} onSubmit={submitSearch} />
        <IconButton
            color="inherit"
            aria-label="Search"
            disabled={disabled}
            onClick={submitSearch}
        >
            <SearchIcon />
        </IconButton>
    </>
}