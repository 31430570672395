import React from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

interface MainAppBarProps {
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    bar: {
        backgroundColor: theme.palette.primary.light,
    },
}))

const MainAppBar: React.FC<MainAppBarProps> = ({ children }) => {
    const trigger = useScrollTrigger();
    const classes = useStyles()
    return (
        <React.Fragment>
            <Slide appear={false} direction="down" in={!trigger}>
                <AppBar position='static' className={classes.bar}>
                    <Toolbar>{children}</Toolbar>
                </AppBar>
            </Slide>
        </React.Fragment>
    );
}

export default MainAppBar;
