"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Converts the normalized structure to a denormalized list
 */
function denormalizeStoreProducts(input) {
    var normalizeGroup = function (sp) { return sp.products.map(function (p) { return (__assign(__assign({}, p), { store: sp.store })); }); };
    if (input instanceof Array) {
        return input.reduce(function (acc, cur) { return acc.concat(normalizeGroup(cur)); }, []);
    }
    else {
        return normalizeGroup(input);
    }
}
exports.denormalizeStoreProducts = denormalizeStoreProducts;
// "official" base units that we are supporting.
// basically standardizing units across packages.
var BaseUnit;
(function (BaseUnit) {
    BaseUnit["KG"] = "KG";
    BaseUnit["L"] = "L";
    BaseUnit["M"] = "M";
    BaseUnit["M2"] = "M2";
    BaseUnit["UNITS"] = "UNITS";
    BaseUnit["UNKNOWN"] = "UNKNOWN";
})(BaseUnit = exports.BaseUnit || (exports.BaseUnit = {}));
var units_1 = require("./units");
exports.toBaseUnit = units_1.toBaseUnit;
exports.cleanUnitStr = units_1.cleanUnitStr;
exports.parseUnitAmountStr = units_1.parseUnitAmountStr;
// stores that we know about
var Store;
(function (Store) {
    Store["REDBARN"] = "REDBARN";
    Store["QUALITY_FOODS"] = "QUALITY_FOODS";
    Store["WALMART"] = "WALMART";
    Store["SAVE_ON_FOODS"] = "SAVE_ON_FOODS";
    Store["THRIFTY_FOODS"] = "THRIFTY_FOODS";
    Store["WHOLESALE_CLUB"] = "WHOLESALE_CLUB";
    Store["LONDON_DRUGS"] = "LONDON_DRUGS";
    Store["THE_MARKETS"] = "THE_MARKETS";
    Store["COUNTRY_GROCER"] = "COUNTRY_GROCER";
    Store["SUPERSTORE"] = "SUPERSTORE";
    Store["BULKBARN"] = "BULKBARN";
})(Store = exports.Store || (exports.Store = {}));
// returns a string representation of each enum
function getStoreName(store) {
    switch (store) {
        case Store.SAVE_ON_FOODS:
            return 'Save On Foods';
        case Store.QUALITY_FOODS:
            return 'Quality Foods';
        case Store.THE_MARKETS:
            return 'The Markets';
        case Store.WALMART:
            return 'Walmart';
        case Store.WHOLESALE_CLUB:
            return 'Wholesale Club';
        case Store.THRIFTY_FOODS:
            return 'Thrifty Foods';
        case Store.LONDON_DRUGS:
            return 'London Drugs';
        case Store.COUNTRY_GROCER:
            return 'Country Grocer';
        case Store.SUPERSTORE:
            return 'Superstore';
        case Store.REDBARN:
            return 'RedBarn';
        case Store.BULKBARN:
            return 'BulkBarn';
    }
}
exports.getStoreName = getStoreName;
var removeSpecialCharRegex = /[\s\?\:\\\/\(\)&\+@;,=\$'"\{\}\^~#<>]/g;
function createStoreProductID(store) {
    var strs = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        strs[_i - 1] = arguments[_i];
    }
    return store + "_" + encodeURI(strs.join('_').replace(removeSpecialCharRegex, ''));
}
exports.createStoreProductID = createStoreProductID;
