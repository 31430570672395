import React from 'react'
import ReactDOM from 'react-dom'
import Authorized from './components/Authorized'
import './styles.css'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import GoldStarsPage from './pages/goldstars'
import SavedSearchesPage from './pages/saved-searches'
import EditSavedSearchPage from './pages/saved-searches/EditSearch'
import ReceiptsPage from './pages/receipts'
import SearchPage from './pages/search'
import SearchListPage from './pages/search-list'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#8ab16e',
      light: '#bbe39d',
      dark: '#5b8142',
      contrastText: '#000000',
    },
    secondary: {
      main: '#fc7e01',
      light: '#ffaf43',
      dark: '#c24f00',
      contrastText: '#000000',
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Authorized>
          <Switch>
            <Route path="/goldstars">
              <GoldStarsPage />
            </Route>
            <Route exact={true} path="/search-list/:name">
              <SearchListPage />
            </Route>
            <Route exact={true} path="/searches">
              <SavedSearchesPage />
            </Route>
            <Route path="/searches/:keywords">
              <EditSavedSearchPage />
            </Route>
            <Route path="/receipts">
              <ReceiptsPage />
            </Route>
            <Route path={['/search/:keywords', '/search', '/']}>
              <SearchPage />
            </Route>
          </Switch>
        </Authorized>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  rootElement,
)
