import React from 'react'
import ListCard from '../ListCard'
import { makeStyles, Theme } from '@material-ui/core'
import StoreAvatar from './StoreAvatar'
import IconOpenInNew from "@material-ui/icons/OpenInNewTwoTone"
import IconDelete from '@material-ui/icons/Delete'
import { Product } from './types'
import ProductDetailsBlock from './ProductDetailsBlock'
import { PPUText } from './PPUText'

export interface ProductListCardProps {
    // a grocery product
    product: Product
    // Whether the product is a good price or not
    isGoodPrice: boolean
    // Called if the user chooses to delete this product
    onDelete: () => void
}

const useStyles = makeStyles<Theme, { isGoodPrice: boolean }>(theme => ({
    ppu: p => ({
        fontWeight: ((p.isGoodPrice) ? theme.typography.fontWeightBold : undefined),
        color: ((p.isGoodPrice) ? theme.palette.secondary.main : undefined),
        fontSize: theme.typography.h5.fontSize,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2)
    })
}))

const ProductListCard: React.FC<ProductListCardProps> = ({ product, isGoodPrice, onDelete }) => {
    const classes = useStyles({ isGoodPrice })
    return <ListCard
        avatar={<StoreAvatar store={product.store} />}
        middleContent={<ProductDetailsBlock product={product} />}
        rightContent={
            <PPUText className={classes.ppu} value={product.pricePerUnit} isGoodPrice={isGoodPrice} />
        }
        actions={getActions(product, onDelete)}
    />
}

function getActions(product: Product, onDelete: () => void) {
    let actions = [
        {
            label: 'Delete',
            tooltip: 'Delete product',
            icon: <IconDelete />,
            onClick: onDelete
        }
    ]
    if (product.productURL) {
        actions.push({
            label: 'Open Store Page',
            tooltip: 'View in store',
            icon: <IconOpenInNew />,
            onClick: () => {
                if (product.productURL) {
                    window.open(product.productURL, '_blank', 'noreferrer')
                }
            }
        })
    }
    return actions
}

export default ProductListCard