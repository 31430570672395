import React, { useState } from 'react'
import { makeStyles, IconButton, Theme, styled, Box, useTheme, useMediaQuery, Tooltip, Button } from '@material-ui/core'
import IconCloseDrawer from "@material-ui/icons/ChevronLeft"

interface ListCardProps {
    onClick?: (e: React.MouseEvent) => void
    avatar: JSX.Element
    middleContent: JSX.Element
    rightContent: JSX.Element
    actions?: ProductAction[]
}


interface ProductAction {
    label: string
    tooltip: string
    icon: JSX.Element
    onClick: () => void
}

const ActionItem: React.FC<{ action?: ProductAction }> = ({ action }) => {
    const theme = useTheme()
    const isWide = useMediaQuery(theme.breakpoints.up('md'))

    if (action === undefined) {
        return <Box></Box>
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        action.onClick()
    }

    if (isWide) {
        return <Tooltip title={action.tooltip}>
            <Button
                onClick={handleClick}
                startIcon={action.icon}
            >
                {action.label}
            </Button>
        </Tooltip>
    }


    return <Tooltip title={action.tooltip}>
        <IconButton onClick={handleClick} >
            {action.icon}
        </IconButton>
    </Tooltip>
}


const useStyles = makeStyles<Theme, { toggled: boolean }>(theme => ({
    root: {
        display: "grid",
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "56px 1fr 120px",
        },
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "56px 1fr 200px",
        },
        overflow: "hidden",
        margin: theme.spacing(1),
        gridColumnGap: theme.spacing(1),
        height: `calc(100% - ${theme.spacing(2)}px)`,
    },
    avatar: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        gridRow: "1",
        gridColumn: "1",
        paddingLeft: theme.spacing(1),
        zIndex: 20,
        backgroundColor: theme.palette.background.paper
    },
    middle: {
        display: 'inline-grid',
        gridRow: "1",
        zIndex: 10,
        gridColumn: "2"
    },
    right: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gridRow: "1",
        zIndex: 10,
        gridColumn: "3"
    },
    actions: p => ({
        gridRow: "1",
        zIndex: 15,
        transitionProperty: "all",
        transitionDuration: "0.5s",
        transitionTimingFunction: "ease",
        transform: p.toggled ? "" : "translateX(-100%)",
        borderRadius: '0px 20px 20px 0px',
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / 3"
        },
        [theme.breakpoints.down("xs")]: {
            gridColumn: "2 / 4"
        },
        backgroundColor: theme.palette.background.paper,
        display: "grid",
        gridTemplateColumns: "1fr min-content"
    }),
}))

const ListCard: React.FC<ListCardProps> = props => {
    const [toggled, setToggled] = useState(false)
    const classes = useStyles({ toggled })
    const handleToggle = (e: React.MouseEvent) => {
        if (props.onClick) {
            props.onClick(e)
        }
        if (props.actions !== undefined) {
            setToggled(!toggled)
        }
    }
    return (
        <article onClick={handleToggle} className={classes.root}>
            <div className={classes.avatar}>{props.avatar}</div>
            <div className={classes.middle}>{props.middleContent}</div>
            <div className={classes.right}>{props.rightContent}</div>
            <div className={classes.actions}>
                <ActionsList>{props.actions?.map((a, idx) => <ActionItem key={idx} action={a} />)}</ActionsList>
                <IconButton onClick={handleToggle}>
                    <IconCloseDrawer />
                </IconButton>
            </div>
        </article>
    )
}

const ActionsList = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyItems: 'center'
})

export default ListCard