
export const rowSuggestions = [
    { name: "acetaminophen", unit: "UNITS", search: "vitamin c", category: "Grocery", subCategories: ["Supplement (vitamin)"] },
    { name: "acorn squash", unit: "KG", search: "", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "adanac tight small", unit: "UNITS", search: "", category: "Clothing", subCategories: ["Clothes"] },
    { name: "adhesive strips", unit: "UNITS", search: "", category: "Housing", subCategories: ["Repairs"] },
    { name: "advanced tune up", unit: "UNITS", search: "", category: "Transportation", subCategories: ["Bike", "Maintenance"] },
    { name: "ajwain seeds", unit: "UNITS", search: "", category: "Discretionary", subCategories: ["garden", "hobbies"] },
    { name: "albacore tuna", unit: "KG", search: "tuna sashimi", category: "Grocery", subCategories: ["Protein", "Meat (protein)", "Frozen"] },
    { name: "all purpose flour", unit: "KG", search: "all purpose flour", category: "Grocery", subCategories: ["Staple", "Baking"] },
    { name: "aloe refill hand soap", unit: "KG", search: "hand soap", category: "Housing", subCategories: ["Cleaning"] },
    { name: "aluminum flex duct", unit: "UNITS", search: "", category: "Discretionary", subCategories: ["Hobbies"] },
    { name: "ambrosia apples", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "apple cider vinegar", unit: "L", search: "cider vinegar", category: "Grocery", subCategories: ["Sauce"] },
    { name: "apples", unit: "UNKNOWN", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "apples ambrosia", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "apples envy/smitten", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "apples eve / diva", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "apples fuji", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "apples gala", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)", "snack"] },
    { name: "apples pink lady", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "apples spartan", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "ataulfo mangoes", unit: "UNITS", search: "mangoes", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "avacados", unit: "UNITS", search: "avocados", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "bag return", unit: "UNITS", search: "", category: "Grocery", subCategories: [] },
    { name: "baking powder", unit: "UNITS", search: "baking powder", category: "Grocery", subCategories: ["Baking"] },
    { name: "bananas", unit: "KG", search: "bananas", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "bar tape", unit: "UNITS", search: "", category: "Transportation", subCategories: ["Bike", "Maintenance", "Repairs"] },
    { name: "bartlett pears", unit: "KG", search: "pears", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "bathroom tissue", unit: "UNITS", search: "bathroom tissue", category: "Grocery", subCategories: ["hygiene"] },
    { name: "beans", unit: "KG", search: "beans", category: "Grocery", subCategories: ["Protein", "Bean (protein)"] },
    { name: "beef ground, lean", unit: "KG", search: "ground beef", category: "Grocery", subCategories: ["Meat (protein)", "Protein"] },
    { name: "beer", unit: "KG", search: "beer", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "beets", unit: "KG", search: "beets", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "bell peppers", unit: "KG", search: "peppers", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "berry blend", unit: "KG", search: "frozen fruit", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "berry sugar", unit: "KG", search: "sugar", category: "Grocery", subCategories: ["Sugar", "Baking"] },
    { name: "bio-cycle cleaner", unit: "L", search: "cleaner", category: "Transporation", subCategories: ["Biking"] },
    { name: "black beans", unit: "KG", search: "dried beans", category: "Grocery", subCategories: ["Staple", "Protein", "Bean (protein)"] },
    { name: "black eyed peas", unit: "KG", search: "dried beans", category: "Grocery", subCategories: ["Staple", "Protein", "Bean (protein)"] },
    { name: "black grapes", unit: "KG", search: "black grapes", category: "Grocery", subCategories: ["Produce", "Fruit (produce)", "Staple"] },
    { name: "black olives", unit: "KG", search: "olives", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)", "Canned"] },
    { name: "black pepper whole", unit: "KG", search: "black pepper", category: "Grocery", subCategories: ["Spice"] },
    { name: "blanched peanuts", unit: "KG", search: "peanuts", category: "Grocery", subCategories: ["Staple", "Nut/seed (protein)", "Protein"] },
    { name: "bleach", unit: "L", search: "bleach", category: "Grocery", subCategories: ["Hygiene"] },
    { name: "body wash", unit: "L", search: "bodywash", category: "Grocery", subCategories: ["Cosmetic", "Hygiene"] },
    { name: "borax", unit: "KG", search: "borax", category: "Housing", subCategories: ["cleaning"] },
    { name: "bottle depot", unit: "UNITS", search: "", category: "deposit", subCategories: ["bottle deposit"] },
    { name: "bottle return", unit: "UNITS", search: "", category: "Grocery", subCategories: ["Returns"] },
    { name: "boulion base stock", unit: "KG", search: "boulion stock", category: "Grocery", subCategories: ["Staple", "spice"] },
    { name: "braeburn apples", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "broccoli", unit: "KG", search: "broccoli", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "brown sugar", unit: "KG", search: "brown sugar", category: "Grocery", subCategories: ["Baking"] },
    { name: "brussels sprouts", unit: "KG", search: "brussel sprouts", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "bulk carrots", unit: "KG", search: "carrots", category: "Grocery", subCategories: ["Staple", "Vegetable (produce)", "Produce"] },
    { name: "bulk cherry tomatoes", unit: "KG", search: "tomatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "butter", unit: "KG", search: "butter", category: "Grocery", subCategories: ["Dairy", "Fat/oil", "Refrigerated"] },
    { name: "butter sticks unsalted", unit: "KG", search: "butter sticks", category: "Grocery", subCategories: ["Dairy", "Staple", "Fat/oil", "Refrigerated"] },
    { name: "butter unsalted", unit: "KG", search: "butter", category: "Grocery", subCategories: ["Dairy", "Staple", "Fat/oil", "Refrigerated"] },
    { name: "butter salted", unit: "KG", search: "butter", category: "Grocery", subCategories: ["Dairy", "Staple", "Fat/oil", "Refrigerated"] },
    { name: "butternut squash", unit: "KG", search: "squash", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "cabbage", unit: "KG", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "cabbage green", unit: "KG", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "cabbage red", unit: "KG", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "cable housing", unit: "UNITS", search: "", category: "Transportation", subCategories: ["Bike", "Maintenance", "Parts"] },
    { name: "canesten", unit: "UNITS", search: "canestan", category: "Housing", subCategories: ["storage"] },
    { name: "canned tomatoes", unit: "L", search: "canned tomato", category: "Grocery", subCategories: ["canned", "Vegetable (produce)"] },
    { name: "canola oil", unit: "L", search: "canola oil", category: "Grocery", subCategories: ["Staple"] },
    { name: "canola spray", unit: "KG", search: "canola oil", category: "Grocery", subCategories: ["Premade", "Fat/oil"] },
    { name: "cantaloupe", unit: "KG", search: "melon", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "caraway seeds", unit: "KG", search: "caroway", category: "Discretionary", subCategories: ["Garden", "Hobbies"] },
    { name: "carbonated spring water", unit: "KG", search: "carbonated water", category: "Grocery", subCategories: ["Junk", "Premade", "Beverage (junk)"] },
    { name: "carrots", unit: "KG", search: "carrots", category: "Grocery", subCategories: ["Staple", "Vegetable (produce)", "Produce"] },
    { name: "cauliflower", unit: "UNITS", search: "UNITS", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "celery", unit: "KG", search: "celery", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "cheddar", unit: "KG", search: "cheese", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "cheese", unit: "KG", search: "cheese", category: "Groceries", subCategories: ["Dairy", "Refrigerated"] },
    { name: "chick pea flour", unit: "KG", search: "pea flour", category: "Grocery", subCategories: ["Protein", "Bean (protein)"] },
    { name: "chicken", unit: "UNKNOWN", search: "chicken", category: "Grocery", subCategories: ["Protein", "Meat (protein)"] },
    { name: "chicken breast", unit: "KG", search: "chicken breast", category: "Grocery", subCategories: ["Meat (protein)", "Protein"] },
    { name: "chicken drumsticks", unit: "KG", search: "chicken drumstick", category: "meat", subCategories: ["Meat (protein)", "Protein"] },
    { name: "chicken legs", unit: "UNKNOWN", search: "chicken legs", category: "Grocery", subCategories: ["Protein", "Meat (protein)"] },
    { name: "chicken stock", unit: "KG", search: "chicken stock", category: "Grocery", subCategories: ["Staple", "Spice"] },
    { name: "chicken thighs", unit: "KG", search: "chicken thigh", category: "Grocery", subCategories: ["Protein", "Meat (protein)"] },
    { name: "chickpeas", unit: "KG", search: "dried beans", category: "Grocery", subCategories: ["Bean (protein)", "Protein", "Dried"] },
    { name: "chips", unit: "KG", search: "chips", category: "Grocery", subCategories: ["Junk", "Snack (junk)"] },
    { name: "chlorine free bleach", unit: "KG", search: "bleach", category: "Housing", subCategories: ["Cleaning"] },
    { name: "chocolate", unit: "KG", search: "chocolate", category: "Grocery", subCategories: ["Dessert (junk)", "Baking", "Junk"] },
    { name: "chocolate bar", unit: "KG", search: "chocolate", category: "Grocery", subCategories: ["Dessert (junk)", "Baking", "Junk"] },
    { name: "chocolate chips", unit: "KG", search: "chocolate", category: "Grocery", subCategories: ["Dessert (junk)", "Baking", "Junk"] },
    { name: "cilantro", unit: "UNITS", search: "cilantro", category: "Grocery", subCategories: ["Produce", "Herb (produce)"] },
    { name: "cleaning gloves", unit: "UNITS", search: "cleaning gloves", category: "Housing", subCategories: ["Cleaning"] },
    { name: "cling wrap", unit: "M", search: "cling wrap", category: "Housing", subCategories: ["Equipment"] },
    { name: "club soda", unit: "UNITS", search: "club soda", category: "Grocery", subCategories: ["Beverage (junk)", "Junk"] },
    { name: "coarse salt", unit: "KG", search: "salt", category: "Grocery", subCategories: ["Staple", "Spice"] },
    { name: "cocoa powder", unit: "KG", search: "cocoa", category: "Grocery", subCategories: ["Baking"] },
    { name: "coconut milk", unit: "L", search: "coconut milk", category: "Groceries", subCategories: ["Staple", "Canned", "Sauce", "Oil/fat"] },
    { name: "coconut milk 18%", unit: "L", search: "coconut milk", category: "Grocery", subCategories: ["Staple", "Canned", "Sauce", "Oil/fat"] },
    { name: "coffee", unit: "KG", search: "coffee", category: "Grocery", subCategories: ["Coffee", "Dried"] },
    { name: "coffee whole bean fair trade", unit: "KG", search: "coffee whole bean fair trade", category: "Grocery", subCategories: ["Coffee", "Dried"] },
    { name: "compost bags", unit: "UNITS", search: "compost bags", category: "Housing", subCategories: ["Equipment"] },
    { name: "container deposit", unit: "UNITS", search: "", category: "Grocery", subCategories: ["Deposit"] },
    { name: "coriander", unit: "KG", search: "coriander seed", category: "Grocery", subCategories: ["Spice"] },
    { name: "coriander seed", unit: "KG", search: "coriander seed", category: "Grocery", subCategories: ["Spice"] },
    { name: "corn starch", unit: "KG", search: "corn starch", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "cornmeal", unit: "KG", search: "cornmeal", category: "Grocery", subCategories: ["Baking"] },
    { name: "cream cheese", unit: "KG", search: "cream cheese", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "crushed chillies", unit: "KG", search: "dried chili peppers", category: "Grocery", subCategories: ["Spice", "Dried"] },
    { name: "cucumbers", unit: "UNITS", search: "cucumbers", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "cycling long tights w sm", unit: "UNKNOWN", search: "", category: "Housing", subCategories: ["Repairs"] },
    { name: "dark chocolate block", unit: "KG", search: "chocolate", category: "Grocery", subCategories: ["Dessert (junk)", "Junk", "Baking"] },
    { name: "dark chocolate chips", unit: "KG", search: "chocolate chips", category: "Grocery", subCategories: ["Dessert (junk)", "Junk", "Baking"] },
    { name: "dark raisins", unit: "KG", search: "raisins", category: "Grocery", subCategories: ["Staple", "Dried", "Produce", "Fruit (produce)"] },
    { name: "deodorant", unit: "KG", search: "deodorant", category: "Housing", subCategories: ["Hygiene"] },
    { name: "deposit", unit: "UNITS", search: "", category: "Grocery", subCategories: ["Deposit"] },
    { name: "diet gingerale", unit: "KG", search: "gingerale", category: "Grocery", subCategories: ["Beverage (junk)", "Junk", "Premade"] },
    { name: "dijon mustard", unit: "L", search: "mustard", category: "Grocery", subCategories: ["Sauce"] },
    { name: "dish detergent", unit: "UNITS", search: "dish detergent", category: "Housing", subCategories: ["Cleaning"] },
    { name: "dish scrubbie", unit: "UNITS", search: "", category: "Housing", subCategories: ["Cleaning"] },
    { name: "dish soap", unit: "L", search: "dish soap", category: "Housing", subCategories: ["Cleaning"] },
    { name: "dragon boat mix", unit: "L", search: "snack mix", category: "Groceries", subCategories: ["Junk", "Snack (junk)", "Premade"] },
    { name: "dried beans", unit: "KG", search: "dried beans", category: "Grocery", subCategories: ["Bean (protein)", "Protein", "Dried", "Staple"] },
    { name: "dry roasted unsalted peanuts", unit: "KG", search: "", category: "Grocery", subCategories: ["Staple", "Protein", "Nut/seed (protein)", "Dried"] },
    { name: "earl grey tea", unit: "KG", search: "tea", category: "Grocery", subCategories: ["Staple", "Tea", "Dried"] },
    { name: "edamame", unit: "KG", search: "edamame", category: "Grocery", subCategories: ["Bean (protein)", "Produce", "Protein", "Vegetable (produce)"] },
    { name: "egg noodles", unit: "KG", search: "chow mein noodles", category: "Grocery", subCategories: ["Staple", "Premade"] },
    { name: "egg roll wraps", unit: "KG", search: "", category: "Grocery", subCategories: ["Bread (premade)", "Premade", "Refrigerated"] },
    { name: "eggs", unit: "UNITS", search: "free eggs", category: "Grocery", subCategories: ["Egg (protein)", "Protein", "Refrigerated"] },
    { name: "ehc", unit: "UNITS", search: "lettuce", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "elbow pasta", unit: "KG", search: "pasta", category: "Grocery", subCategories: ["Staple", "Dried"] },
    { name: "enviro handling", unit: "UNITS", search: "", category: "Personal and discretionary", subCategories: ["Hobbies", "Kitchen"] },
    { name: "vermicelli", unit: "KG", search: "pasta", category: "Grocery", subCategories: ["Staple", "Dried", "Premade"] },
    { name: "extra old cheddar cheese", unit: "KG", search: "cheese", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "felt pads", unit: "UNITS", search: "", category: "Housing", subCategories: ["Equipment"] },
    { name: "fennel", unit: "KG", search: "fennel", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)", "Herb (produce)"] },
    { name: "feta", unit: "KG", search: "feta cheese", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "filone bread", unit: "KG", search: "bread", category: "Grocery", subCategories: ["Bread (premade)", "Premade"] },
    { name: "firm tofu", unit: "KG", search: "tofu", category: "Grocery", subCategories: ["Protein", "Bean (protein)", "Refrigerated"] },
    { name: "flour", unit: "KG", search: "", category: "Grocery", subCategories: ["Baking", "Staple"] },
    { name: "flour whole wheat whole grain", unit: "KG", search: "", category: "Grocery", subCategories: ["Staple", "Baking"] },
    { name: "food grade grease 8640063", unit: "UNITS", search: "", category: "Housing", subCategories: ["Equipment", "Maintenance"] },
    { name: "fresh turkey", unit: "KG", search: "turkey", category: "Grocery", subCategories: ["Protein", "Meat (protein)", "Refrigerated"] },
    { name: "fried potato crinkle", unit: "KG", search: "fries", category: "Grocery", subCategories: ["Junk", "Frozen", "Premade"] },
    { name: "frozen berry blend", unit: "KG", search: "frozen fruit", category: "Grocery", subCategories: ["Fruit (produce)", "Produce", "Frozen"] },
    { name: "frozen blueberries", unit: "KG", search: "frozen fruit", category: "Grocery", subCategories: ["Fruit (produce)", "Produce", "Frozen"] },
    { name: "frozen fruit", unit: "KG", search: "frozen fruit", category: "Grocery", subCategories: ["Fruit (produce)", "Produce", "Frozen"] },
    { name: "frozen mango", unit: "UNITS", search: "frozen fruit", category: "Grocery", subCategories: ["Produce"] },
    { name: "frozen mixed berries", unit: "KG", search: "frozen fruit", category: "Grocery", subCategories: ["Fruit (produce)", "Produce", "Frozen"] },
    { name: "frozen soy bean", unit: "KG", search: "frozen soy bean", category: "Grocery", subCategories: ["Protein", "Frozen", "Bean (protein)"] },
    { name: "fuji apples", unit: "UNKNOWN", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "gala apples", unit: "UNKNOWN", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "galangal", unit: "KG", search: "galangal", category: "Grocery", subCategories: ["Produce", "Spice", "Herb (produce)"] },
    { name: "garlic", unit: "KG", search: "garlic cloves", category: "Grocery", subCategories: ["Produce", "Herb (produce)"] },
    { name: "gift card", unit: "UNITS", search: "", category: "Discretionary", subCategories: ["Gifts"] },
    { name: "ginger", unit: "KG", search: "ginger", category: "Grocery", subCategories: ["Produce", "Herb (produce)"] },
    { name: "ginger ale diet", unit: "KG", search: "ginger ale", category: "Grocery", subCategories: ["Premade", "Beverage (junk)", "Junk"] },
    { name: "golden delicious apples", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Produce (fruit)", "Staple"] },
    { name: "grape tomatoes", unit: "KG", search: "tomatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "greek yogurt plain 2%", unit: "KG", search: "yogurt", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "green cabbage", unit: "KG", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "green lentils", unit: "UNITS", search: "lentils", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce", "Dried"] },
    { name: "green olives", unit: "UNITS", search: "green olives", category: "Grocery", subCategories: ["Produce", "Canned"] },
    { name: "green onion seeds", unit: "UNITS", search: "", category: "Discretionary", subCategories: ["Garden (hobby)"] },
    { name: "green onions", unit: "UNITS", search: "green onions", category: "Grocery", subCategories: ["Produce", "Herb (produce)"] },
    { name: "green tea", unit: "KG", search: "green tea", category: "Grocery", subCategories: ["Tea", "hobby", "homebrew"] },
    { name: "ground beef", unit: "KG", search: "ground beef", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Refrigerated"] },
    { name: "ground cinnamon", unit: "KG", search: "cinnamon", category: "Grocery", subCategories: ["Spice", "Baking"] },
    { name: "ground hot chillies", unit: "KG", search: "chilis ground", category: "Grocery", subCategories: ["Dried", "Spice"] },
    { name: "ground pork", unit: "KG", search: "organic pork", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Refrigerated"] },
    { name: "ground turkey", unit: "KG", search: "organic pork", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Refrigerated"] },
    { name: "habanero peppers", unit: "KG", search: "", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)", "Spice"] },
    { name: "half&half cream", unit: "L", search: "", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "ham", unit: "L", search: "shampoo", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Refrigerated", "Cured"] },
    { name: "hand blender", unit: "UNKNOWN", search: "", category: "Housing", subCategories: ["Kitchen"] },
    { name: "honey", unit: "KG", search: "honey", category: "Grocery", subCategories: ["Fermentation", "Brewing", "Baking", "Sugar"] },
    { name: "honeycrisp apples", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Staple", "Fruit (produce)", "Produce"] },
    { name: "hungarian paprika", unit: "KG", search: "paprika", category: "Grocery", subCategories: ["Staple", "Spice"] },
    { name: "ice cream", unit: "L", search: "", category: "Grocery", subCategories: ["Dairy", "Junk", "Dessert (junk)", "Frozen"] },
    { name: "ice cream sandwich", unit: "L", search: "", category: "Grocery", subCategories: ["Dairy", "Junk", "Dessert (junk)", "Frozen"] },
    { name: "iceberg lettuce", unit: "UNITS", search: "iceburg lettuce", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "jalapeno pepper", unit: "KG", search: "jalapeno peppers", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "jonagold apples", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce))", "Staple"] },
    { name: "ketchup", unit: "L", search: "ketchup", category: "Grocery", subCategories: ["Sauce", "Premade", "Junk"] },
    { name: "kettle chips", unit: "KG", search: "kettle chips", category: "Grocery", subCategories: ["Snack (junk)", "Premade", "Junk"] },
    { name: "king oyster mushrooms", unit: "KG", search: "mushrooms", category: "Grocery", subCategories: ["Protein", "Mushroom (protein)", "Produce", "Refrigerated"] },
    { name: "lard", unit: "L", search: "", category: "Grocery", subCategories: ["Staple", "Oil/fat", "Baking"] },
    { name: "large eggs", unit: "UNITS", search: "eggs", category: "Grocery", subCategories: ["Baking", "Staple", "Protein", "Eggs (protein)"] },
    { name: "large lemons", unit: "UNITS", search: "lemons", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "latex gloves", unit: "UNITS", search: "latex gloves", category: "Housing", subCategories: ["Cleaning"] },
    { name: "lean ground beef", unit: "KG", search: "ground beef", category: "Grocery", subCategories: ["Meat (protein)", "Protein"] },
    { name: "leeks", unit: "KG", search: "leeks", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "lemons", unit: "UNITS", search: "lemons", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "lettuce romaine", unit: "UNITS", search: "lettuce", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "limes", unit: "UNITS", search: "limes", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "liquid honey", unit: "KG", search: "liquid honey", category: "Grocery", subCategories: ["Sugar", "Baking", "hobby", "fermentation", "homebrew"] },
    { name: "long grain brown rice", unit: "KG", search: "brown rice", category: "Grocery", subCategories: ["Staple", "Dried"] },
    { name: "lye crystals", unit: "KG", search: "", category: "Discretionary", subCategories: ["Hobbies"] },
    { name: "mandarins", unit: "KG", search: "mandarin oranges", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "mangoes", unit: "UNITS", search: "mangoes", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "maple syrup", unit: "L", search: "maple syrup", category: "Grocery", subCategories: ["Staple", "Baking", "Sugar"] },
    { name: "matsaman curry paste", unit: "L", search: "curry paste", category: "Grocery", subCategories: ["Staple", "Spice"] },
    { name: "mayonnaise", unit: "L", search: "mayonnaise", category: "Grocery", subCategories: ["Sauce", "Oil/fat", "Staple"] },
    { name: "medium cheddar cheese", unit: "KG", search: "cheese", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "microfiber tea towels", unit: "UNKNOWN", search: "microfiber towel", category: "Housing", subCategories: ["Cleaning"] },
    { name: "milk", unit: "L", search: "milk", category: "Groceries", subCategories: ["Dairy", "Refrigerated"] },
    { name: "milk 1%", unit: "L", search: "", category: "Grocery", subCategories: ["Dairy", "Milk", "Refrigerated"] },
    { name: "milk chocolate block", unit: "KG", search: "", category: "Grocery", subCategories: ["Junk", "Dessert (junk)", "Baking"] },
    { name: "milk chocolate raisins", unit: "KG", search: "", category: "Grocery", subCategories: ["Dessert (junk)", "Junk", "Baking"] },
    { name: "milk powder", unit: "KG", search: "", category: "Grocery", subCategories: ["Staple", "Dairy", "Dried"] },
    { name: "mixed salami", unit: "KG", search: "", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Premade", "Refrigerated", "Cured"] },
    { name: "molasses", unit: "KG", search: "molasses", category: "Grocery", subCategories: ["Baking", "Sugar"] },
    { name: "mozzarella cheese", unit: "KG", search: "", category: "Grocery", subCategories: ["Dairy", "Refrigerated", "Protein"] },
    { name: "multi purpose cloths 12x24\"", unit: "UNITS", search: "cloths", category: "Housing", subCategories: ["Cleaning"] },
    { name: "multi purpose contact solution", unit: "L", search: "contact solution", category: "Housing", subCategories: ["Bathroom"] },
    { name: "mung beans", unit: "KG", search: "mung beans", category: "Grocery", subCategories: ["Protein", "Bean (protein)", "Dried"] },
    { name: "mushroom organic white", unit: "KG", search: "mushrooms organic", category: "Grocery", subCategories: ["Produce", "Mushroom (protein)", "Refrigerated"] },
    { name: "mushrooms", unit: "KG", search: "", category: "Grocery", subCategories: ["Protein", "Mushroom (protein)", "Refrigerated"] },
    { name: "mustard powder", unit: "KG", search: "mustard powder", category: "Grocery", subCategories: ["Spice", "Dried"] },
    { name: "navel oranges", unit: "KG", search: "oranges", category: "Grocery", subCategories: ["Produce", "Produce (fruit)"] },
    { name: "navy beans", unit: "KG", search: "", category: "Grocery", subCategories: ["Staple", "Protein", "Bean (protein)", "Dried"] },
    { name: "nectarines", unit: "KG", search: "nectarines", category: "Grocery", subCategories: ["Produce", "Produce (fruit)"] },
    { name: "oats", unit: "KG", search: "oats", category: "Grocery", subCategories: ["Staple", "Baking", "Dried"] },
    { name: "old cheddar cheese", unit: "KG", search: "old cheddar cheese", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "onions", unit: "KG", search: "onions", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "onions green", unit: "UNITS", search: "onions green", category: "Grocery", subCategories: ["Produce", "Herb (produce))"] },
    { name: "onions red", unit: "KG", search: "onions", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "onions yellow", unit: "KG", search: "onions", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "onions yellow small", unit: "KG", search: "onions", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "oranges", unit: "KG", search: "oranges", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "oranges cara cara", unit: "KG", search: "oranges", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "oranges navel", unit: "KG", search: "oranges", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "organic bananas", unit: "KG", search: "bananas", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "organic beef", unit: "UNKNOWN", search: "organic beef", category: "Grocery", subCategories: ["Protein", "Meat (protein)"] },
    { name: "organic broccoli", unit: "KG", search: "broccoli", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "organic cabbage green", unit: "KG", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "organic carrots", unit: "KG", search: "carrots", category: "Grocery", subCategories: ["Staple", "Vegetable (produce)", "Produce"] },
    { name: "organic chicken thigh", unit: "KG", search: "organic chicken thigh", category: "Grocery", subCategories: ["Meat (protein)", "Protein"] },
    { name: "organic limes", unit: "UNITS", search: "limes", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "organic mangoes", unit: "UNITS", search: "mangoes", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "organic oranges", unit: "UNITS", search: "mangoes", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "organic pork", unit: "UNKNOWN", search: "organic pork", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Refrigerated"] },
    { name: "organic smoked paprika", unit: "KG", search: "smoked paprika", category: "Grocery", subCategories: ["Spice", "Dried"] },
    { name: "organic steel cut oats", unit: "KG", search: "steel cut oats", category: "Grocery", subCategories: ["Staple", "Baking", "Dried"] },
    { name: "organic yogurt fat free", unit: "KG", search: "organic yogurt", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "oyster sauce", unit: "L", search: "oyster sauce", category: "Grocery", subCategories: ["Sauce", "Premade"] },
    { name: "panko bread crumbs", unit: "KG", search: "panko bread crumbs", category: "Grocery", subCategories: ["Bread (premade)", "Premade"] },
    { name: "paper bag", unit: "UNITS", search: "", category: "Housing", subCategories: ["Storage"] },
    { name: "paper towel", unit: "M", search: "peppers", category: "Housing", subCategories: ["Cleaning"] },
    { name: "paprika", unit: "KG", search: "paprika", category: "Grocery", subCategories: ["Spice", "Dried"] },
    { name: "parchment paper", unit: "M2", search: "parchment paper", category: "Grocery", subCategories: ["Baking"] },
    { name: "parmesano reggiano", unit: "KG", search: "parmesan cheese", category: "Grocery", subCategories: ["Dairy", "Spice", "Refrigerated"] },
    { name: "parsnips", unit: "KG", search: "", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "pasta", unit: "KG", search: "", category: "Grocery", subCategories: ["Staple", "Premade", "Dried"] },
    { name: "pasta sauce", unit: "L", search: "pasta sauce", category: "Grocery", subCategories: ["Sauce", "Premade"] },
    { name: "peanut butter", unit: "KG", search: "peanut butter", category: "Grocery", subCategories: ["Staple", "Nut/seed (protein)", "Protein"] },
    { name: "peanut butter natural", unit: "KG", search: "", category: "Grocery", subCategories: ["Protein", "Nut/seed (protein)", "Staple"] },
    { name: "peanuts", unit: "KG", search: "", category: "Grocery", subCategories: ["Nut/seed (protein)", "Staple", "Protein"] },
    { name: "pearl barley", unit: "KG", search: "barley", category: "Grocery", subCategories: ["Dried", "Staple"] },
    { name: "pears", unit: "KG", search: "pears", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "pears anjou", unit: "KG", search: "pears", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "pears bartlett", unit: "KG", search: "pears", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "pecan halves", unit: "KG", search: "pecans", category: "Grocery", subCategories: ["Nut/seed (protein)", "Protein", "Baking"] },
    { name: "pecans", unit: "KG", search: "pecans", category: "Grocery", subCategories: ["Nut/seed (protein)", "Protein", "Dried"] },
    { name: "peppers", unit: "UNKNOWN", search: "", category: "Grocery", subCategories: ["Produce", "Fruit (produce)", "Refrigerated"] },
    { name: "peppers bell green", unit: "KG", search: "", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)", "Refrigerated"] },
    { name: "peppers habanero", unit: "KG", search: "", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce", "Spice"] },
    { name: "peppers jalapeno green", unit: "KG", search: "jalapeno peppers", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "pickling cucumber", unit: "KG", search: "pickling cucumber", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)", "Hobby"] },
    { name: "pineapple slices", unit: "UNITS", search: "pineapples", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "pita pocket", unit: "KG", search: "pita pocket", category: "Grocery", subCategories: ["Bread (premade)", "Premade"] },
    { name: "plain yogurt", unit: "L", search: "yogurt", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "plasic tote bin", unit: "UNITS", search: "tote bin", category: "Housing", subCategories: ["Storage"] },
    { name: "pork leg roast", unit: "KG", search: "Pork", category: "Grocery", subCategories: ["Protein", "Meat (protein)", "Refrigerated"] },
    { name: "pork rib roast", unit: "KG", search: "organic pork", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Refrigerated"] },
    { name: "pork roast", unit: "KG", search: "organic pork", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Refrigerated"] },
    { name: "potato chips", unit: "KG", search: "chips", category: "Grocery", subCategories: ["Junk", "Snack (junk)", "Premade"] },
    { name: "potatoes", unit: "KG", search: "potatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "potatoes russet", unit: "KG", search: "potatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "potatoes yellow", unit: "KG", search: "potatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "potatoes yukon", unit: "KG", search: "potatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "potting soil", unit: "L", search: "potting soil", category: "Discretionary", subCategories: ["Garden (hobby)"] },
    { name: "pressurized air", unit: "KG", search: "", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "protein powder", unit: "KG", search: "protein powder", category: "Grocery", subCategories: ["Staple", "Protein", "Supplement (protein)", "Dried"] },
    { name: "pumpkin canned", unit: "L", search: "pumpkin puree", category: "Grocery", subCategories: ["Produce", "Fruit (produce)", "Staple", "Canned", "Canned (produce)"] },
    { name: "pumpkin puree", unit: "L", search: "pumpkin puree", category: "Grocery", subCategories: ["Produce", "Fruit (produce)", "Staple", "Canned", "Canned (produce)"] },
    { name: "pumpkin seeds", unit: "L", search: "pumpkin puree", category: "Grocery", subCategories: ["Produce", "Fruit (produce)", "Staple", "Canned", "Canned (produce)"] },
    { name: "pure vanilla extract", unit: "L", search: "vanilla extract", category: "Grocery", subCategories: ["Baking", "Spice"] },
    { name: "raisins", unit: "KG", search: "raisins", category: "Grocery", subCategories: ["Staple", "Fruit", "Dried"] },
    { name: "raw cashews", unit: "KG", search: "", category: "Grocery", subCategories: ["Staple", "Nut/seed (protein)"] },
    { name: "raw sesame seed", unit: "UNITS", search: "sesame seeds", category: "Grocery", subCategories: ["Oil/fat", "Nut/seed (protein)", "Protein", "Dried"] },
    { name: "real mayo", unit: "L", search: "mayonnaise", category: "Grocery", subCategories: ["Sauce"] },
    { name: "recycle fee", unit: "UNKNOWN", search: "", category: "Grocery", subCategories: ["deposit"] },
    { name: "red cabbage", unit: "KG", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "red curry paste", unit: "KG", search: "curry paste", category: "Grocery", subCategories: ["Sauce", "Spice", "Premade"] },
    { name: "red kidney beans", unit: "KG", search: "dried beans", category: "Grocery", subCategories: ["Bean (protein)", "Protein", "Dried"] },
    { name: "red lentils", unit: "KG", search: "dried beans", category: "Grocery", subCategories: ["Bean (protein)", "Protein", "Dried"] },
    { name: "red onions", unit: "KG", search: "onions", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "red peppers", unit: "UNKNOWN", search: "bell peppers", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "red river", unit: "KG", search: "red river", category: "Grocery", subCategories: ["Baking", "Premade", "Dried"] },
    { name: "reduced produce", unit: "KG", search: "", category: "Grocery", subCategories: ["Produce"] },
    { name: "reduced produce mango", unit: "KG", search: "", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "refried beans", unit: "L", search: "refried beans", category: "Grocery", subCategories: ["Protein", "Bean (protein)", "Canned", "Premade"] },
    { name: "regular bleach", unit: "L", search: "bleach", category: "Housing", subCategories: ["Cleaning"] },
    { name: "revlon powder blush", unit: "UNITS", search: "", category: "", subCategories: ["cosmetic"] },
    { name: "rice", unit: "KG", search: "rice", category: "Grocery", subCategories: ["Staple", "Dried"] },
    { name: "rice sticks medium (rice noodles)", unit: "UNITS", search: "rice noodles", category: "Grocery", subCategories: ["Premade", "Dried"] },
    { name: "rice vinegar", unit: "L", search: "rice vinegar", category: "Grocery", subCategories: ["Staple", "Sauce"] },
    { name: "roasted chicken breast deli", unit: "KG", search: "roast chicken breast", category: "Grocery", subCategories: ["Protein", "Meat (protein)"] },
    { name: "roasted chicken breast sliced", unit: "KG", search: "roast chicken breast", category: "Grocery", subCategories: ["Protein", "Meat (protein)"] },
    { name: "roasted peanuts", unit: "KG", search: "peanuts", category: "Grocery", subCategories: ["Staple", "Nut/seed (protein)", "Protein", "Dried"] },
    { name: "roasting chicken", unit: "KG", search: "chicken", category: "Grocery", subCategories: ["Meat (protein)", "Protein"] },
    { name: "rogers sugar", unit: "KG", search: "brown sugar", category: "Grocery", subCategories: ["Baking", "Sugar"] },
    { name: "rolled oats", unit: "KG", search: "rolled oats", category: "Grocery", subCategories: ["Staple", "Baking", "Dried"] },
    { name: "rolled oats old fashioned", unit: "KG", search: "rolled oats", category: "Grocery", subCategories: ["Staple", "Baking", "Dried"] },
    { name: "rotini pasta", unit: "KG", search: "", category: "Grocery", subCategories: ["Premade", "Dried"] },
    { name: "rubbermaid canister", unit: "UNITS", search: "", category: "Housing", subCategories: ["Storage"] },
    { name: "russet potato", unit: "KG", search: "potatos", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "s/f noodles", unit: "KG", search: "chow mein noodles", category: "Grocery", subCategories: ["Premade"] },
    { name: "salish apples", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Produce", "Fruit (produce)"] },
    { name: "salsa", unit: "L", search: "salsa", category: "Grocery", subCategories: ["Snack (junk)", "Premade"] },
    { name: "salt", unit: "L", search: "salt", category: "Grocery", subCategories: ["Spice"] },
    { name: "sambal olek", unit: "L", search: "sambal olek", category: "Grocery", subCategories: ["Spice", "Sauce"] },
    { name: "sandwich bags", unit: "KG", search: "", category: "Housing", subCategories: ["Storage", "Kitchen"] },
    { name: "santa cruz apple juice", unit: "L", search: "apple juice", category: "Grocery", subCategories: ["Beverage (junk)", "Hobby", "Brewing"] },
    { name: "sausage", unit: "KG", search: "sausage", category: "Grocery", subCategories: ["Meat (protein)", "Refrigerated"] },
    { name: "savoy cabbage", unit: "UNITS", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "scrub brush", unit: "UNITS", search: "scrub brush", category: "Housing", subCategories: ["Cleaning"] },
    { name: "sealy x-firm pillow", unit: "UNITS", search: "pillow", category: "Housing", subCategories: ["Bedroom"] },
    { name: "semi sweet chocolate", unit: "KG", search: "chocolate", category: "Grocery", subCategories: ["Baking", "Dessert (junk)", "Junk"] },
    { name: "semi sweet chocolate chips", unit: "KG", search: "chocolate chips", category: "Grocery", subCategories: ["Baking", "Dessert (junk)", "Junk"] },
    { name: "shanghai bok choy", unit: "KG", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "shimano b01s resin disc brake pad", unit: "UNITS", search: "", category: "Transportation", subCategories: ["bike", "maintenace", "parts"] },
    { name: "shimano cn-hg95 10s bike chain", unit: "UNITS", search: "", category: "Transportation", subCategories: ["Bike", "Parts", "Repairs"] },
    { name: "shimano g02s resin disc brake", unit: "UNITS", search: "", category: "Transportation", subCategories: ["bike", "maintenace", "parts"] },
    { name: "short grain brown rice", unit: "KG", search: "brown rice", category: "Grocery", subCategories: ["Staple", "Dried"] },
    { name: "shreaded coconut", unit: "KG", search: "shreaded coconut", category: "Grocery", subCategories: ["Dried", "Nut/seed", "Baking"] },
    { name: "sliced bacon", unit: "UNKNOWN", search: "bacon", category: "Grocery", subCategories: ["Meat (protein)", "Protein", "Cured", "Refrigerated"] },
    { name: "sliced almonds", unit: "KG", search: "almonds", category: "Grocery", subCategories: ["Nut/seed (protein)", "Protein", "Dried"] },
    { name: "slow blot cilantro seeds", unit: "UNKNOWN", search: "cilantro seeds", category: "Discretionary", subCategories: ["Garden (hobby)", "Hobby"] },
    { name: "smitten apples", unit: "KG", search: "apples", category: "Grocery", subCategories: ["Fruit (produce)", "Produce"] },
    { name: "soda cola diet", unit: "UNITS", search: "club soda", category: "Grocery", subCategories: ["Beverage (junk)", "Junk", "Premade", "Canned"] },
    { name: "sour cream", unit: "L", search: "sour cream", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "sour cream light", unit: "L", search: "sour cream", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "soy beans", unit: "KG", search: "soy beans", category: "Grocery", subCategories: ["Protein", "Beans (protein)", "Dried"] },
    { name: "soy sauce", unit: "L", search: "soy sauce", category: "Grocery", subCategories: ["Staple", "Sauce"] },
    { name: "spaghetti pasta", unit: "KG", search: "", category: "Grocery", subCategories: ["Dried", "Premade"] },
    { name: "spaghetti squash", unit: "KG", search: "", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "spaghettini", unit: "KG", search: "pasta", category: "Grocery", subCategories: ["Premade", "Dried"] },
    { name: "spring roll wrapper", unit: "KG", search: "", category: "Grocery", subCategories: ["Premade", "Refrigerated"] },
    { name: "squash", unit: "KG", search: "squash", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "squash butternut", unit: "KG", search: "squash", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "sriracha chili sauce", unit: "L", search: "sriracha", category: "Grocery", subCategories: ["Sauce", "Spice"] },
    { name: "steak", unit: "UNITS", search: "steak", category: "Grocery", subCategories: ["Meat (protein)", "Protein"] },
    { name: "steel cut oats", unit: "KG", search: "steel cut oats", category: "Grocery", subCategories: ["Staple", "Baking", "Dried"] },
    { name: "stitch holes by hand", unit: "UNITS", search: "", category: "Clothing", subCategories: ["repairs"] },
    { name: "sugar", unit: "KG", search: "sugar", category: "Grocery", subCategories: ["Sugar", "Baking", "Staple"] },
    { name: "sugar fine white", unit: "KG", search: "sugar", category: "Grocery", subCategories: ["Sugar", "Baking"] },
    { name: "sui choy", unit: "KG", search: "cabbage", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "sultana raisins", unit: "KG", search: "raisins", category: "Grocery", subCategories: ["Staple", "Produce", "Dried", "Produce (fruit)"] },
    { name: "sunflower seeds", unit: "KG", search: "sunflower seeds", category: "Grocery", subCategories: ["Staple", "Dried", "Protein", "Nut/seed (protein)"] },
    { name: "super mac mix", unit: "UNKNOWN", search: "", category: "Grocery", subCategories: ["Premade", "Frozen", "Junk", "Snack (junk)"] },
    { name: "sweet potatoes", unit: "KG", search: "potatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "tea orange pekoe", unit: "KG", search: "tea", category: "Grocery", subCategories: ["Tea", "Dried"] },
    { name: "thompson raisins", unit: "KG", search: "raisins", category: "Grocery", subCategories: ["Staple", "Produce", "Produce (fruitS", "Dried"] },
    { name: "tofu", unit: "KG", search: "", category: "Grocery", subCategories: ["Protein", "Bean (protein)", "Refrigerated"] },
    { name: "tofu super firm", unit: "KG", search: "tofu", category: "Grocery", subCategories: ["Protein", "Bean (protein)", "Refrigerated"] },
    { name: "toilet paper", unit: "UNITS", search: "toilet paper", category: "Housing", subCategories: ["hygiene"] },
    { name: "tomato paste", unit: "L", search: "tomato paste", category: "Grocery", subCategories: ["Staple", "Canned", "Produce", "Produce (vegetable)"] },
    { name: "tomatoes", unit: "KG", search: "tomatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "tonic water", unit: "KG", search: "", category: "Grocery", subCategories: ["Produce", "Fruit (produce)", "Canned", "Junk", "Beverage (junk)"] },
    { name: "toothpaste", unit: "L", search: "toothpaste", category: "Housing", subCategories: ["bathroom", "oral"] },
    { name: "tortillas", unit: "KG", search: "tortillas", category: "Grocery", subCategories: ["Bread (premade)", "Snack", "Premade"] },
    { name: "tortilla chips", unit: "KG", search: "tortilla chips", category: "Grocery", subCategories: ["Premade", "Snack (junk)", "Junk"] },
    { name: "unsalted butter", unit: "KG", search: "butter", category: "Grocery", subCategories: ["Dairy", "Staple", "Fat/oil", "Refrigerated"] },
    { name: "unsalted peanuts", unit: "KG", search: "peanuts", category: "Grocery", subCategories: ["Staple", "Nut/seed (protein)", "Dried"] },
    { name: "unsalted sunflower seeds", unit: "KG", search: "sunflower seeds", category: "Grocery", subCategories: ["Nut/seed (protein)", "Protein", "Staple", "Dried"] },
    { name: "unsweetened chocolate", unit: "KG", search: "chocolate", category: "Grocery", subCategories: ["Baking", "Junk", "Dessert (junk)"] },
    { name: "vanilla ice cream", unit: "L", search: "ice cream", category: "Grocery", subCategories: ["Dessert (junk)", "Frozen", "Dairy", "Junk", "Snack (junk)"] },
    { name: "vermiculite", unit: "L", search: "vermiculite", category: "Discretionary", subCategories: ["Hobbies (garden)", "Hobbies (mushroom)"] },
    { name: "washing soda", unit: "L", search: "washing soda", category: "Housing", subCategories: ["Cosmetic", "Hygiene"] },
    { name: "wheel true and spoke replacement", unit: "UNKNOWN", search: "", category: "Discretionary", subCategories: ["Biking (hobby)", "Repair", "Maintenance"] },
    { name: "whey powder", unit: "KG", search: "whey powder", category: "Grocery", subCategories: ["Staple", "Protein", "Supplement (protein)", "Dried"] },
    { name: "whipping cream", unit: "L", search: "whipping cream", category: "Grocery", subCategories: ["Dairy", "Junk", "Refrigerated"] },
    { name: "white beans", unit: "KG", search: "beans", category: "Grocery", subCategories: ["Bean (protein)", "Protein", "Dried"] },
    { name: "white bread flour", unit: "KG", search: "", category: "Grocery", subCategories: ["Baking", "Staple"] },
    { name: "white chocolate block", unit: "KG", search: "white chocolate", category: "Grocery", subCategories: ["Dessert (junk)", "Junk", "Baking"] },
    { name: "white eggs", unit: "KG", search: "white eggs", category: "Grocery", subCategories: ["Refrigerated", "Baking", "Protein", "Eggs (protein)"] },
    { name: "white granulated sugar", unit: "KG", search: "sugar", category: "Grocery", subCategories: ["Staple", "Baking", "Sugar"] },
    { name: "white kidney beans", unit: "KG", search: "dried beans", category: "Grocery", subCategories: ["Bean (protein)", "Protein", "Staple", "Dried"] },
    { name: "white veggie fed eggs", unit: "L", search: "veggie fed eggs", category: "Grocery", subCategories: ["Staple", "Eggs (protein)", "Refrigerated"] },
    { name: "whole clove", unit: "KG", search: "cloves", category: "Grocery", subCategories: ["Spice", "Dried"] },
    { name: "whole coriander", unit: "KG", search: "coriander seed", category: "Grocery", subCategories: ["Spice", "Dried"] },
    { name: "whole cumin", unit: "L", search: "cumin", category: "Grocery", subCategories: ["Staple", "Spice", "Dried"] },
    { name: "whole grain flour", unit: "KG", search: "", category: "Grocery", subCategories: ["Staple", "Baking"] },
    { name: "whole grain whole wheat flour", unit: "KG", search: "", category: "Grocery", subCategories: ["Staple", "Baking"] },
    { name: "whole nutmeg", unit: "KG", search: "whole nutmeg", category: "Grocery", subCategories: ["Staple", "Baking", "Spice"] },
    { name: "whole wheat flour", unit: "KG", search: "whole wheat flour", category: "Grocery", subCategories: ["Staple", "Baking"] },
    { name: "whole wheat kaiser", unit: "KG", search: "whole wheat flour", category: "Grocery", subCategories: ["Staple", "Baking"] },
    { name: "xl eggs", unit: "UNITS", search: "free eggs", category: "Grocery", subCategories: ["Egg (protein)", "Protein"] },
    { name: "yams", unit: "KG", search: "Yams", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "yellow onions", unit: "KG", search: "onions", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "yellow potatoes", unit: "KG", search: "potatoes", category: "Grocery", subCategories: ["Vegetable (produce)", "Produce"] },
    { name: "yogurt", unit: "L", search: "yogurt", category: "Grocery", subCategories: ["Dairy", "Refrigerated"] },
    { name: "ziploc freezer", unit: "UNITS", search: "ziploc", category: "Housing", subCategories: ["Storage"] },
    { name: "zucchini", unit: "KG", search: "zucchini", category: "Grocery", subCategories: ["Produce", "Vegetable (produce)"] },
    { name: "1.1 mm s/s slick 2100 (shifter cable)", unit: "UNITS", search: "", category: "Transportation", subCategories: ["bike", "maintenance", "repairs"] },
    { name: "1.6 mm ss brake cable 1700", unit: "UNITS", search: "", category: "Transportation", subCategories: ["bike", "maintenance", "repairs"] },
    { name: "1.9 l wide mason jars", unit: "UNITS", search: "mason jars", category: "Discretionary", subCategories: ["canning", "hobby", "fermentation", "homebrew"] },
    { name: "12pk wide mason jar lids", unit: "UNITS", search: "", category: "Housing", subCategories: ["canning", "hobby", "fermentation", "homebrew"] },
    { name: "2% evapourated milk", unit: "KG", search: "evaporated milk", category: "Grocery", subCategories: ["Staple", "Canned", "Dairy"] },
    { name: "6.1 l omni clear storage boxes", unit: "UNITS", search: "clear storage boxes", category: "Housing", subCategories: ["Storage"] },
    { name: "6pk 1.9l mason jars", unit: "UNITS", search: "mason jars", category: "Discretionary", subCategories: ["Hobby", "Fermentation", "Homebrew"] },
    { name: "99% isopropyl alcohol", unit: "L", search: "isopropyl", category: "Housing", subCategories: ["Cleaning"] },
]

export const allProductNames = [
    "acetaminophen",
    "acorn squash",
    "adanac tight small",
    "adhesive strips",
    "advanced tune up",
    "ajwain seeds",
    "albacore tuna",
    "all purpose flour",
    "aloe refill hand soap",
    "aluminum flex duct",
    "ambrosia apples",
    "apple cider vinegar",
    "apples",
    "apples ambrosia",
    "apples envy/smitten",
    "apples eve / diva",
    "apples fuji",
    "apples gala",
    "apples pink lady",
    "apples spartan",
    "ataulfo mango",
    "ataulfo mangoes",
    "avacados",
    "bag return",
    "baking powder",
    "bananas",
    "bar tape",
    "bartlett pears",
    "bathroom tissue",
    "beans",
    "beef ground, lean",
    "beer",
    "beets",
    "bell peppers",
    "berry blend",
    "berry sugar",
    "bio-cycle cleaner",
    "black beans",
    "black eyed peas",
    "black grapes",
    "black olives",
    "black pepper whole",
    "blanched peanuts",
    "bleach",
    "body wash",
    "borax",
    "bottle depot",
    "bottle return",
    "boulion base stock",
    "braeburn apples",
    "broccoli",
    "brown sugar",
    "brussels sprouts",
    "bulk carrots",
    "bulk cherry tomatoes",
    "butter",
    "butter sticks unsalted",
    "butter unsalted",
    "butter salted",
    "butternut squash",
    "cabbage",
    "cabbage green",
    "cabbage red",
    "cable housing",
    "canesten",
    "canned tomatoes",
    "canola oil",
    "canola spray",
    "cantaloupe",
    "caraway seeds",
    "carbonated spring water",
    "carrots",
    "cat food",
    "cauliflower",
    "celery",
    "chain install",
    "champion14g spoke",
    "cheddar",
    "cheese",
    "chick pea flour",
    "chicken",
    "chicken breast",
    "chicken drumsticks",
    "chicken legs",
    "chicken stock",
    "chicken thighs",
    "chickpeas",
    "chips",
    "chlorine free bleach",
    "chocolate",
    "chocolate bar",
    "chocolate chips",
    "cilantro",
    "cleaning gloves",
    "cling wrap",
    "club soda",
    "coarse salt",
    "cocoa powder",
    "coconut milk",
    "coconut milk 18%",
    "coffee",
    "coffee whole bean fair trade",
    "compost bags",
    "container deposit",
    "coriander",
    "coriander seed",
    "corn starch",
    "cornmeal",
    "cream cheese",
    "crushed chillies",
    "cucumbers",
    "cycling long tights w sm",
    "dark chocolate block",
    "dark chocolate chips",
    "dark raisins",
    "deodorant",
    "deposit",
    "diet gingerale",
    "dijon mustard",
    "dish detergent",
    "dish scrubbie",
    "dish soap",
    "dragon boat mix",
    "dried beans",
    "dry roasted unsalted peanuts",
    "earl grey tea",
    "edamame",
    "egg noodles",
    "egg roll wraps",
    "eggs",
    "ehc",
    "elbow pasta",
    "enviro handling",
    "vermicelli",
    "extra old cheddar cheese",
    "felt pads",
    "fennel",
    "feta",
    "filone bread",
    "firm tofu",
    "flour",
    "flour whole wheat whole grain",
    "food grade grease 8640063",
    "fresh turkey",
    "fried potato crinkle",
    "frozen berry blend",
    "frozen blueberries",
    "frozen fruit",
    "frozen mango",
    "frozen mixed berries",
    "frozen soy bean",
    "fuji apples",
    "gala apples",
    "galangal",
    "garlic",
    "gift card",
    "ginger",
    "ginger ale diet",
    "golden delicious apples",
    "grape tomatoes",
    "greek yogurt plain 2%",
    "green cabbage",
    "green lentils",
    "green olives",
    "green onion seeds",
    "green onions",
    "green tea",
    "ground beef",
    "ground cinnamon",
    "ground hot chillies",
    "ground pork",
    "ground turkey",
    "habanero peppers",
    "half&half cream",
    "ham",
    "hand blender",
    "honey",
    "honeycrisp apples",
    "hungarian paprika",
    "ice cream",
    "ice cream sandwich",
    "iceberg lettuce",
    "jalapeno pepper",
    "jonagold apples",
    "ketchup",
    "kettle chips",
    "king oyster mushrooms",
    "l'oreal powder",
    "lard",
    "large eggs",
    "large lemons",
    "latex gloves",
    "lean ground beef",
    "leeks",
    "lemons",
    "lettuce romaine",
    "limes",
    "liquid honey",
    "long grain brown rice",
    "lye crystals",
    "mandarins",
    "mangoes",
    "maple syrup",
    "matsaman curry paste",
    "mayonnaise",
    "medium cheddar cheese",
    "microfiber tea towels",
    "milk",
    "milk 1%",
    "milk chocolate block",
    "milk chocolate raisins",
    "milk powder",
    "mixed salami",
    "molasses",
    "mozzarella cheese",
    "multi purpose cloths 12x24\"",
    "multi purpose contact solution",
    "mung beans",
    "mushroom organic white",
    "mushrooms",
    "mustard powder",
    "navel oranges",
    "navy beans",
    "nectarines",
    "oats",
    "old cheddar cheese",
    "onions",
    "onions green",
    "onions red",
    "onions yellow",
    "onions yellow small",
    "oranges",
    "oranges cara cara",
    "oranges navel",
    "organic bananas",
    "organic beef",
    "organic broccoli",
    "organic cabbage green",
    "organic carrots",
    "organic chicken thigh",
    "organic limes",
    "organic mangoes",
    "organic oranges",
    "organic pork",
    "organic smoked paprika",
    "organic steel cut oats",
    "organic yogurt fat free",
    "oyster sauce",
    "panko bread crumbs",
    "paper bag",
    "paper towel",
    "paprika",
    "parchment paper",
    "parmesano reggiano",
    "parsnips",
    "pasta",
    "pasta sauce",
    "peanut butter",
    "peanut butter natural",
    "peanuts",
    "pearl barley",
    "pears",
    "pears anjou",
    "pears bartlett",
    "pecan halves",
    "pecans",
    "peppers",
    "peppers bell green",
    "peppers habanero",
    "peppers jalapeno green",
    "pickling cucumber",
    "pineapple slices",
    "pita pocket",
    "plain yogurt",
    "plasic tote bin",
    "pork leg roast",
    "pork rib roast",
    "pork roast",
    "potato chips",
    "potatoes",
    "potatoes russet",
    "potatoes yellow",
    "potatoes yukon",
    "potting soil",
    "pressurized air",
    "protein powder",
    "pumpkin canned",
    "pumpkin puree",
    "pumpkin seeds",
    "pure vanilla extract",
    "raisins",
    "raw cashews",
    "raw sesame seed",
    "real mayo",
    "recycle fee",
    "red cabbage",
    "red curry paste",
    "red kidney beans",
    "red lentils",
    "red onions",
    "red peppers",
    "red river",
    "reduced produce",
    "reduced produce mango",
    "refried beans",
    "regular bleach",
    "revlon powder blush",
    "rice",
    "rice sticks medium (rice noodles)",
    "rice vinegar",
    "roasted chicken breast deli",
    "roasted chicken breast sliced",
    "roasted peanuts",
    "roasting chicken",
    "rogers sugar",
    "rolled oats",
    "rolled oats old fashioned",
    "rotini pasta",
    "rubbermaid canister",
    "russet potato",
    "s/f noodles",
    "salish apples",
    "salsa",
    "salt",
    "sambal olek",
    "sandwich bags",
    "santa cruz apple juice",
    "sausage",
    "savoy cabbage",
    "scrub brush",
    "sealy x-firm pillow",
    "semi sweet chocolate",
    "semi sweet chocolate chips",
    "shanghai bok choy",
    "shimano b01s resin disc brake pad",
    "shimano cn-hg95 10s bike chain",
    "shimano g02s resin disc brake",
    "short grain brown rice",
    "shreaded coconut",
    "sliced bacon",
    "sliced almonds",
    "slow blot cilantro seeds",
    "smitten apples",
    "soda cola diet",
    "sour cream",
    "sour cream light",
    "soy beans",
    "soy sauce",
    "spaghetti pasta",
    "spaghetti squash",
    "spaghettini",
    "spring roll wrapper",
    "squash",
    "squash butternut",
    "sriracha chili sauce",
    "steak",
    "steel cut oats",
    "stitch holes by hand",
    "sugar",
    "sugar fine white",
    "sui choy",
    "sultana raisins",
    "sunflower seeds",
    "super mac mix",
    "sweet potatoes",
    "tea orange pekoe",
    "thompson raisins",
    "tofu",
    "tofu super firm",
    "toilet paper",
    "tomato paste",
    "tomatoes",
    "tonic water",
    "toothpaste",
    "tortillas",
    "tortilla chips",
    "unsalted butter",
    "unsalted peanuts",
    "unsalted sunflower seeds",
    "unsweetened chocolate",
    "vanilla ice cream",
    "vermiculite",
    "washing soda",
    "wheel true and spoke replacement",
    "whey powder",
    "whip cream",
    "whipping cream",
    "white beans",
    "white bread flour",
    "white chocolate block",
    "white eggs",
    "white granulated sugar",
    "white kidney beans",
    "white veggie fed eggs",
    "whole clove",
    "whole coriander",
    "whole cumin",
    "whole grain flour",
    "whole grain whole wheat flour",
    "whole nutmeg",
    "whole wheat flour",
    "whole wheat kaiser",
    "wool hat",
    "xl eggs",
    "yams",
    "yellow onions",
    "yellow potatoes",
    "yogurt",
    "ziploc freezer",
    "zucchini",
    "1.1 mm s/s slick 2100 (shifter cable)",
    "1.6 mm ss brake cable 1700",
    "1.9 l wide mason jars",
    "12pk wide mason jar lids",
    "2% evapourated milk",
    "22 oz spray bottle",
    "6.1 l omni clear storage boxes",
    "6pk 1.9l mason jars",
    "8 x 1 1/4 wood screws",
    "99% isopropyl alcohol",
    "9x11 sheet 220g sand paper",
]

export const allStores = [
    'Bulk Barn',
    'Shoppers',
    'MEC',
    'BC Liquor Stores',
    'BC Tranist',
    'Hudsons Bay',
    'Cook Culture',
    'Capital Iron',
    'WIN',
    'Salvation Army',
    'Value Village',
    'Ocean River',
    'Fisgard Market',
    'Village Butcher',
    'Peppers Market',
    'Christies Carriage House Pub',
    'Google',
    'Rogers Communications',
    'Italian Food Imports',
    'Tartan Toque',
    'AWS',
    'Amazon',
    'Microsoft',
    'New Balance',
    'Rogers Chocolates',
    'Chocolat Favoris',
    'A&W',
    'McDonalds',
    'Digital Ocean',
    'GitHub',
    'CIBC',
    'TD',
    'Oak Bay Dental',
    'UVic',
    'Russel Books',
    'The Mantra',
    'Royal Spice',
    'Discovery Coffee',
    'Oreganos Pizza',
    'BC Hydro',
    'Azuma',
    'Shiki',
    'Kissako',
    "Thrifty Foods",
    "Save On Foods",
    "WalMart",
    "Country Grocer",
    "Home Depot",
    'Fish Hair Salon',
    'Victory Barber & Brand',
    'Shell',
    'Esso',
    'Petro Canada',
    'Co-Op Gas',
    'Tim Hortons',
    'Safeway',
    'A&B Tailor',
    'Taco Justice',
    'Telus',
    "Home Hardware",
    "Wholesale Club",
    "Superstore",
    "Quality Foods",
    "The Markets",
    "Red Barn",
    "Root Cellar",
    "Fairway",
    "Hamilton Hops & Grapes",
    "Garden Works",
    "London Drugs",
    'Home Depot',
    'Canadian Tire',
    "Shoppers Drug Mart",
    'Vessel Liquor',
    'Borderfree',
    'Fitbit',
    "Pharmasave",
    'Spotify',
]

export const allBrands = [
    "Columbia",
    "Que Pasa",
    "Kicking Horse",
    "Compliments",
    "mccain",
    "so nice",
    "Hellmann's",
    "Western Family",
    "Home Gardener",
    "OSC",
    "Rooster",
    "No Name",
    "Kraft",
    "Quality",
    "India's own",
    "Real Thai",
    "Lactania",
    "PC Blue Menu",
    "No name",
    "Suraj",
    "Windsor",
    "PC",
    "Miracle grow",
    "Level Ground",
    "Rogers",
    "Island Farms",
    "Dairyland",
    "Natural Delights",
    "Colgate",
    "Lee Kum Kee",
    "Heinz",
    "Whistler Performance Lubricants",
    "Sliquid H2O",
    "Utopia Care",
    "Whal",
    "Dempster's",
    "Lee and Perrin",
    "PC blue menu",
    "Diamond G",
    "Waterbridge",
    "Great value",
    "Old fashioned maple crest",
    "Aroy-D",
    "Kosa",
    "Canesten",
    "Farkay",
    "Schweppes",
    "Great Value",
    "Aylmer",
    "6 mile",
    "Vita Sana",
    "Equate",
    "Dove",
    "ED Smith",
    "Naturel",
    "Armstrong",
    "Shimano",
    "Island farms",
    "Bergen",
    "Rubbermaid",
    "Kikoman",
    "Natrel",
    "Tuff Guy",
    "Cheemo",
    "Life Brand",
    "Island gold",
    "Bergen Farms",
    "L'oreal",
    "Revlon",
    "Icebreaker",
    "Frys",
    "Cracker barrel Kraft",
    "Kracks",
    "Santa Cruz",
    "Elias",
    "Better than Bouillon",
    "Kicking horse",
    "Green Giant",
    "Kikkoman",
    "Cracker Barrel",
    "Java",
    "MEC",
    "bausch and lomb",
    "Expo",
    "Coca Cola",
    "Presidesnt's choice",
    "Ziggies",
    "Woolwich Original",
    "Kirkland",
    "Paradox",
    "Tuff guy",
    "Bausch+Lomb",
    "Jameson",
    "Carolans",
    "Dempsters",
    "Plackers",
    "Michelin",
    "100% Whey",
    "Kabuli",
    "Coppertone",
    "Azuri",
    "Nantes",
    "Fish",
    "Huy Fong",
    "Six Fortune",
    "Lesaffre",
    "Global Vinters",
    "Huy Fong Foods",
    "Sonora",
    "Bergen farms",
    "Pringles",
    "OralB",
    "Western family",
    "Bernardin",
    "Dial",
    "Market Fresh",
    "rogers",
    "Texana",
    "Kettle",
    "local",
    "Level ground",
    "Tribal Java",
    "Continental",
    "Peyote",
    "Golden Boy",
    "20 Mule Team",
    "Schwepps",
    "Complements",
    "San Marcos",
    "natrel",
    "Valentina",
    "Voets",
    "Sun spun",
    "La perla",
    "Amira",
    "Mendosa",
    "Victory",
    "Golden",
    "Laperla",
    "Country natural",
    "Speed stick",
    "Salomon",
    "Island Gold",
    "Wonder bread",
    "Lalvin",
    "Clorox",
    "Hellmanns",
    "Crosby's",
    "London Orchard",
    "Dempster",
    "Driftwood",
    "Vancouver Island Brewing VIB",
    "Moleskine",
    "Hellmans",
    "Eden",
    "Bee Maid",
    "Nikwax",
    "Ziggy's",
    "Jergens",
    "C.I.L",
    "Thrifty",
    "Sunspun",
    "Mizkan",
    "Cock brand",
    "Exact",
    "Bradner Farms",
    "coats & clark",
    "Lucerine",
    "Prana",
    "de vine",
    "huy fong foods inc",
    "fleischmann's",
    "lactania",
    "rooster",
    "bergen",
    "Maille",
    "Libby's",
    "Deli",
    "100% whey protein",
    "equate",
    "great value",
    "hf",
    "dr perma foam",
    "Freybe",
    "Ziploc",
    "Barilla",
    "Zein Tahina",
    "Indias Own",
    "bausch + lomb",
    "Lululemon",
    "Sensations",
    "Haagen Dazs",
    "Keen",
    "Epipen",
    "Teva",
    "Sandoz",
    "PMS",
    "L'Oreal",
    "House Foods",
    "western family",
    "Gillette",
    "Ivory",
]

export const allUnits = ['g', 'kg', 'ml', 'l', 'm', 'm2', 'units', 'lb', 'oz', 'fl. oz']

export const allCategories = [
    'Clothing',
    'Discretionary',
    'Grocery',
    'Housing',
    'Health & Wellness',
    'Transportation',
    'Utilities',
]

export interface SubCategories {
    [index: string]: string[]
}

export const allSubCategories: SubCategories = {
    'Grocery': [
        'Baking',
        'Bean (protein)',
        'Beverage (junk)',
        'Bread (premade)',
        "Canned",
        'Coffee',
        'Cosmetic',
        'Cured',
        'Dairy',
        'Dessert (junk)',
        'Dried',
        'Egg (protein)',
        'Frozen',
        'Fruit (produce)',
        'Herb (produce)',
        'Hygiene',
        'Junk',
        'Meat (protein)',
        'Mushrooms (protein)',
        'Mushrooms (produce)',
        'Nut/seed (protein)',
        'Oil/fat',
        'Premade',
        'Produce',
        'Protein',
        'Refrigerated',
        'Sauce',
        'Snack (junk)',
        'Spice ',
        'Staple',
        'Sugar',
        'Supplement (protein)',
        'Tea',
        'Vegetable (produce)',
    ]
    ,
    'Housing': [
        'Cleaning',
        'Equipment',
        'Furnishing',
        'Rent',
        'Repairs',
        'Storage',
        'Subscriptions',
    ]
    ,
    'Clothing': [
        'Clothes',
        'Shoes',
        'Repairs',
    ]
    ,
    'Health & Wellness': [
        'Dental',
        'Insurance',
        'Medication',
        'Other',
        'Hair Cuts',
        'Sex',
        'Fitness',
        'Specialist',
        'Vision'
    ]
    ,
    'Utility': [
        'Hydro',
        'Cloud storage',
        'Phone',
        'Internet',
    ]
    ,
    'Transportation': [
        'Bike',
        'Car',
        'Ferry',
        'Fuel',
        'Gear',
        'Insurance',
        'Maintenance',
        'Parking',
        'Parts',
        'Public transit',
        'Repairs',
        'Taxi',
    ]
    ,
    'Discretionary': [
        'Alcohol',
        'Art (hobby)',
        'Beauty',
        'Cannabis',
        'Donations',
        'Education',
        'Entertainment',
        'Fermentation (hobby)',
        'Garden (hobby)',
        'Gifts',
        'Hair cuts',
        'Hobby',
        'Homebrew (hobby)',
        'Kitchen (hobby)',
        'Memberships',
        'Mushrooms (hobby)',
        'Outdoors (hobby)',
        'Programming (hobby)',
        'Restaurant',
        'Soap (hobby)',
        'Sports (hobby)',
        'Travel',
    ]
}