import React from "react"

import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import { Unit } from "@grocery/graphql/dist/client/gen-types"

const useStyles = makeStyles(theme => ({
    select: {
        width: "100%"
    }
}))

export interface SelectUnitProps {
    disabled: boolean
    selected?: Unit | null
    handleChange(unit?: Unit | null): void
}

export default function SelectUnit(props: SelectUnitProps) {
    const classes = useStyles()
    const handleChange = (value: string) => {
        switch (value) {
            case 'KG':
                props.handleChange(value)
                break
            case 'L':
                props.handleChange(value)
                break
            case 'M':
                props.handleChange(value)
                break
            case 'M2':
                props.handleChange(value)
                break
            case 'UNITS':
                props.handleChange(value)
                break
            case 'UNKNOWN':
                props.handleChange('UNKNOWN')
                break
            default:
                props.handleChange(undefined)
        }
    }
    return (
        <FormControl disabled={props.disabled} className={classes.select}>
            <InputLabel htmlFor="units">Units</InputLabel>
            <Select
                value={props.selected || 'any'}
                onChange={ev => handleChange(ev.target.value as string)}
                input={<Input name="units" id="units" />}
            >
                <MenuItem value="any">
                    <em>Any (?)</em>
                </MenuItem>
                <MenuItem value="KG">Kilograms (kg)</MenuItem>
                <MenuItem value="L">Litres (l)</MenuItem>
                <MenuItem value="UNITS">Units</MenuItem>
                <MenuItem value="M">Meters (m)</MenuItem>
                <MenuItem value="M2">Square Meters (m&sup2;)</MenuItem>
            </Select>
        </FormControl>
    )
}
