import React, { FC, useState, useEffect, UIEvent } from "react"
import { Typography } from "@material-ui/core"

// describes a registry for list item resize listeners
export interface RowResizeEventRegistry {
  register(listener: RowResizeEventListener): UnregisterCallback
  unregister(listener: RowResizeEventListener): void
  notify(event: ResizeEvent): void
}

// a callback for unregistering a listener
export type UnregisterCallback = () => void

// describes the list item resize event
export type ResizeEvent = {
  // the index (so 0-start) of the row that had a resize event
  rowIndex: number
  // the height of the list item at the time the event was generated
  height: number
}

// describes a listener for list item resize eventss
export interface RowResizeEventListener {
  // accepts a resize event and does something
  (event: ResizeEvent): void
}

export interface LazyRowRenderer {
  (rowIndex: number, top: number, height: number): [JSX.Element, any]
}

// Properties supported by the LazyList component
export interface LazyListProps {
  // The total number of rows to display
  totalRows: number
  // The minimum height of the rows.
  // This should be your default row height.
  minRowHeight: number
  // This renders your custom row components into the list.
  // This gets called a number of times when the list initially loads
  // and also gets called in response to scrolling - to render new rows
  // as they are scrolled into view.
  rowRenderer: LazyRowRenderer
  // This enables you to have rows that can change their size (currently just their height)
  // It works by giving the list access to a registry where it will subscribe to
  // row resize events.
  // Your row components can use this registry to notify the list of changes to their size.
  rowResizeEventRegistry?: RowResizeEventRegistry
  // Optionally specify the total height of the list
  // Default is to fill the parent
  height?: number
  // Optionally specify the total width of the list.
  // Default is to fill parent
  width?: number
  // This is useful for ensuring a smooth scrolling experience by ensuring
  // extra rows are rendered above and below the top/bottom of the list.
  // Especially helpful for data that needs to be fetched over network.
  // The slower your row rendering is, the higher this value should be.
  overscan: number
}

const LazyList: FC<LazyListProps> = props => {
  const {
    totalRows,
    rowResizeEventRegistry,
    height,
    minRowHeight,
    rowRenderer,
    width,
    overscan
  } = props
  // Initialize rowHeights to their minimum heights
  const [rowHeights, updateRowHeight] = useState<number[]>(
    new Array(totalRows)
  ) // sparse array

  const [firstRowIndex, setFirstRowIndex] = useState(0)

  function updateRowHeightFn(rowNum: number) {
    return (height: number) => {
      updateRowHeight(prev => {
        prev[rowNum] = height
        return [...prev]
      })
    }
  }

  useEffect(() => {
    const handler = ({ rowIndex, height }: ResizeEvent) =>
      updateRowHeightFn(rowIndex)(height)
    if (rowResizeEventRegistry === undefined) {
      return
    }
    return rowResizeEventRegistry.register(handler)
    // return () => props.rowResizeEventRegistry.unregister(handler);
  })

  function getRowHeight(rowNum: number) {
    if (rowHeights[rowNum] !== undefined) {
      return rowHeights[rowNum]
    }
    return minRowHeight
  }

  const numRowsThatFitInContainer = (height ? Math.floor(height / minRowHeight) : 1)
  const numRowsToLoad =
    Math.min(totalRows, overscan * 2 + numRowsThatFitInContainer)

  function handleScroll(e: UIEvent<HTMLElement>) {
    let element = e.currentTarget
    const scrolledSparseRowHeights = rowHeights.reduce(
      ...generateSparseRowHeightReducer(
        getRowHeight,
        undefined,
        element.scrollTop
      )
    )
    let rowNum = scrolledSparseRowHeights.numRowsCounted
    if (scrolledSparseRowHeights.rowHeightsSum < element.scrollTop) {
      rowNum += Math.floor(
        (element.scrollTop - scrolledSparseRowHeights.rowHeightsSum) /
        minRowHeight
      )
    }
    const first = Math.max(0, rowNum - overscan)
    const newFirstIndex = Math.min(first, totalRows - numRowsToLoad + 1)
    if (newFirstIndex < overscan / 2 || newFirstIndex > (totalRows - numRowsToLoad) || Math.abs(newFirstIndex - firstRowIndex) > overscan / 2) {
      setFirstRowIndex(newFirstIndex)
    }
  }

  // Calculate inner element height based on
  // 1. minHeight for unrendered rows
  // 2. actual height for rendered rows
  const innerHeight = rowPositioner(
    rowHeights.reduce(...generateSparseRowHeightReducer(getRowHeight)),
    totalRows,
    minRowHeight
  )

  const rowsToMap = Array.from(new Array(numRowsToLoad))

  if (totalRows === 0) {
    return <ol style={{
      height, overflow: "auto",
      width,
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    }} onScroll={handleScroll}>
      <li
        style={{
          height: innerHeight,
          width: "auto",
          position: "relative",
        }}
      ><Typography variant="h6" align="center">No Results</Typography></li>
    </ol>
  }

  return (
    <div style={{ height, overflow: "auto", width }} onScroll={handleScroll}>
      <ol
        style={{
          height: innerHeight,
          width: "auto",
          position: "relative",
          listStyleType: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        {rowsToMap.map(function displayRow(_r, i) {
          const rowNum = firstRowIndex + i
          if (rowNum >= totalRows) {
            return undefined
          }
          const top = rowPositioner(
            rowHeights.reduce(
              ...generateSparseRowHeightReducer(getRowHeight, rowNum)
            ),
            rowNum,
            minRowHeight
          )
          const height = getRowHeight(rowNum)
          const [element, key] = rowRenderer(rowNum, top, height)
          return (
            <Row
              key={key}
              top={top}
              height={height}
            >
              {element}
            </Row>
          )
        })}
      </ol>
    </div>
  )
}

interface RowProps {
  height: number
  top: number
  rest?: any
}

const Row: React.FC<RowProps> = props => {
  return (
    <li
      style={{
        position: "absolute",
        top: props.top,
        width: "100%",
        height: props.height
      }}
    >
      {props.children}
    </li>
  )
}

class RowResizeEventRegistryImpl implements RowResizeEventRegistry {
  private listeners: Set<RowResizeEventListener>
  constructor() {
    this.listeners = new Set()
  }
  register(listener: RowResizeEventListener): UnregisterCallback {
    this.listeners.add(listener)
    return () => this.listeners.delete(listener)
  }
  unregister(listener: RowResizeEventListener): void {
    this.listeners.delete(listener)
  }
  notify(event: ResizeEvent): void {
    this.listeners.forEach(l => l(event))
  }
}

function rowPositioner(
  {
    numRowsCounted,
    rowHeightsSum
  }: { numRowsCounted: number; rowHeightsSum: number },
  rowNum: number,
  minRowHeight: number
) {
  const missingRowHeights = (rowNum - numRowsCounted) * minRowHeight
  return rowHeightsSum + missingRowHeights
}

interface Accumulator {
  numRowsCounted: number
  rowHeightsSum: number
}
// returns [reducerFn, initialAccumulator]
function generateSparseRowHeightReducer(
  getRowHeight: (rowNum: number) => number,
  maxRowNum?: number,
  maxRowHeight?: number
): [
    (arg0: Accumulator, agr1: number, arg2: number) => Accumulator,
    Accumulator
  ] {
  function _generateSparseRowHeightReducer_reducerFn(
    acc: Accumulator,
    _cur: number,
    i: number
  ) {
    if (maxRowNum !== undefined) {
      // Stop summing row heights once we reach the row we are calculated preceding heights for
      if (i >= maxRowNum) {
        return acc
      }
    }
    if (maxRowHeight !== undefined) {
      if (acc.rowHeightsSum >= maxRowHeight) {
        return acc
      }
    }

    return {
      numRowsCounted: acc.numRowsCounted + 1,
      rowHeightsSum: acc.rowHeightsSum + getRowHeight(i)
    }
  }
  return [
    _generateSparseRowHeightReducer_reducerFn,
    { numRowsCounted: 0, rowHeightsSum: 0 }
  ]
}

export const useRegistry = () => {
  const registry = new RowResizeEventRegistryImpl()
  const onResize = (rowIndex: number, height: number) =>
    registry.notify({ rowIndex, height })
  return [registry, onResize]
}

export default LazyList
