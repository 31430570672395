"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var model_1 = require("../model");
var __1 = require("..");
exports.jsonToReceipt = function (id, store, datePurchased, itemsJSON, totalsJSON) {
    var items = itemsJSON.map(rowToReceiptItem);
    var totalsRow = totalsJSON[0] || {};
    __1.log({ totalsRow: totalsRow });
    return {
        id: id,
        store: store,
        datePurchased: datePurchased,
        items: items,
        total: totalsRow['Total'],
        PST: totalsRow['7% PST'] || 0,
        GST: totalsRow['5% GST'] || 0,
    };
};
var parseUnitInfo = function (unitAmountStr, unitStr) {
    var initialUnitAmount = model_1.parseUnitAmountStr(unitAmountStr);
    if (initialUnitAmount === undefined) {
        return [1, model_1.BaseUnit.UNKNOWN];
    }
    var unitInfo = model_1.toBaseUnit(initialUnitAmount, model_1.cleanUnitStr(unitStr));
    var unit = unitInfo.unit;
    var unitAmount = unitInfo ? unitInfo.unitAmount : 1;
    return [unitAmount, unit];
};
var rowToReceiptItem = function (row) {
    var unitAmountStr = row['Quantity'];
    var unitStr = row['Units'] || '';
    var _a = parseUnitInfo(unitAmountStr, unitStr), unitAmount = _a[0], unit = _a[1];
    var price = row['Price'] || 0;
    var pricePerUnit = {
        raw: unitAmountStr + unitStr,
        price: price / unitAmount,
        unit: unit,
    };
    var sizeInfo = {
        unit: unit,
        unitAmount: unitAmount,
        raw: unitAmountStr + unitStr,
    };
    var search = row['Suggested Search'];
    var brand = row['Brand'];
    var notes = row['Notes'];
    var category = row['Category'] || '';
    var subCategoriesString = row['Sub-category'] || notes || '';
    var subCategories = subCategoriesString
        .split(',')
        .map(function (s) { return s.trim(); });
    return {
        price: price,
        product: row['Product'],
        brand: brand,
        savings: row['Savings'] || 0,
        pricePerUnit: pricePerUnit,
        sizeInfo: sizeInfo,
        search: search,
        notes: notes,
        category: category,
        subCategories: subCategories,
    };
};
