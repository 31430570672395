import React, { useRef, useState, useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import SelectUnit from "./SelectUnit"
import { FilterConfigInput, Maybe } from "@grocery/graphql/dist/client/gen-types"
import debounce from "lodash.debounce"
import Box from "@material-ui/core/Box"
import { InputAdornment } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    input: {},
    root: {
        display: "flex",
        backgroundColor: "none",
        flexDirection: "column",
        alignItems: "center",

    },
    form: {
        padding: theme.spacing(2)
    }
}))

export type FilterPanelProps = {
    height?: string | number
    disabled?: boolean
    config: FilterConfigInput
    onConfigChanged?: (config: FilterConfigInput) => void
}

function noop() { }

export default function FilterPanel(props: FilterPanelProps) {
    const classes = useStyles()
    const { config } = props
    const onConfigChanged = props.onConfigChanged ?? noop
    const disabled = props.disabled ?? false
    const [includes, setIncludes] = useState(config.includes)
    useEffect(() => {
        setIncludes(config.includes)
    }, [config.includes])
    const [excludes, setExcludes] = useState(config.excludes)
    useEffect(() => {
        setExcludes(config.excludes)
    }, [config.excludes])

    const [goldStarPrice, setGoldStarPrice] = useState<Maybe<number>>(props.config.goodPPUValueThreshold)
    useEffect(() => {
        setGoldStarPrice(config.goodPPUValueThreshold)
    }, [config.goodPPUValueThreshold])
    const handleConfigChange = useRef(debounce((config: FilterConfigInput) => onConfigChanged(config), 500))
    return (
        <div className={classes.root} style={{ height: props.height }}>
            <SelectUnit disabled={disabled} selected={config.unit} handleChange={unit => onConfigChanged({
                ...config,
                unit,
            })} />
            <TextField
                disabled={disabled}
                id="standard-search"
                label="Exclude Words"
                value={excludes.join(' ')}
                onChange={ev => {
                    const val = ev.currentTarget.value.split(' ')
                    setExcludes(val)
                    handleConfigChange.current({
                        ...config,
                        excludes: val,
                    })
                }
                }
                margin="normal"
                fullWidth
            />
            <TextField
                disabled={disabled}
                id="standard-search"
                label="Include Words"
                margin="normal"
                value={includes.join(' ')}
                onChange={ev => {
                    const val = ev.currentTarget.value.split(' ')
                    setIncludes(val)
                    handleConfigChange.current({
                        ...config,
                        includes: val,
                    })
                }
                }
                fullWidth
            />
            <Box >
                <TextField
                    disabled={disabled}
                    id="gold-star-price-input"
                    label="gold star price"
                    value={goldStarPrice ? goldStarPrice : ''}
                    onChange={ev => {
                        const newGoldStarPrice = ev.currentTarget.value ? parseFloat(ev.currentTarget.value) : undefined
                        setGoldStarPrice(newGoldStarPrice)
                        handleConfigChange.current({
                            ...config,
                            goodPPUValueThreshold: newGoldStarPrice,
                        })
                    }}
                    type="number"
                    className={classes.input}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        endAdornment: <InputAdornment position="end"> {config.unit}</InputAdornment>,
                    }}
                    margin="normal"
                />
            </Box>
        </div>
    )
}
