import { ExtractQueryResultType } from '../lib/typeHelpers'
import { SearchProductsQueryResult, FilterConfig } from '@grocery/graphql/dist/client/gen-types'
import { FilterReducerState, defaultState as defaultFilterState, FilterReducerAction } from './filter'
import {resultsToProductList, ProductListItemProduct} from '../transformers/flatten_store_product'
import { filterProducts } from '../filter'

type ApolloSearchResult = ExtractQueryResultType<SearchProductsQueryResult>
type Results = ApolloSearchResult['search']['storeResults']


export type FilteredProductsReducerState = {
    products: ProductListItemProduct[]
    filteredProducts: ProductListItemProduct[]
    minPPU?: number,
    maxPPU?: number,
} & FilterReducerState

export type FilteredProductsReducerAction =
    | { type: 'results.load', results: Results, keywords: string }
    | FilterReducerAction

export const defaultState: FilteredProductsReducerState = {
    ...defaultFilterState,
    products: [],
    filteredProducts: [],
}

function filterOrNoOp(products: ProductListItemProduct[], config?:FilterConfig){
    if(!config){
        return []
    }
    return filterProducts(products, config)
}
export const reducer = (state: FilteredProductsReducerState, action: FilteredProductsReducerAction): FilteredProductsReducerState => {
    console.log('action', action)
    console.dir(state)
    switch (action.type) {
        case 'filter.changed':
        case 'filter.load':
            return { ...state, filteredProducts: filterOrNoOp(state.products, action.filter) }
        case 'filter.reset':
            return { ...state, filteredProducts: filterOrNoOp(state.products, state.loadedFilter) }
        case 'results.load':
            const { products, minPPU, maxPPU } = resultsToProductList(action.results)
            return { ...state, products, filteredProducts: filterOrNoOp(products, state.loadedFilter), minPPU, maxPPU }
        default:
            return state
    }
}
