import React from 'react'
import ListCard from '../../ListCard'
import StoreAvatar from '../StoreAvatar'
import ProductDetailsBlock from '../ProductDetailsBlock'
import { Product } from '../types'
import { Box, IconButton, Theme, makeStyles } from '@material-ui/core'
import IconAlerting from '@material-ui/icons/Notifications'
import IconAlertingSilenced from '@material-ui/icons/NotificationsOff'
import { PPUText } from '../PPUText'

export interface AlertingProductListCardProps {
    product: Product
    // Whether the product is alerting for this user
    isAlerting: boolean
    // Whether the product has alerting silenced
    isAlertingSilenced: boolean
    // Called if the user wants to suppress alerting on this product
    onSilenceToggle: () => void
}

const useStyles = makeStyles<Theme, { isAlerting: boolean, isAlertingSilenced: boolean }>(theme => ({
    ppu: p => ({
        // fontWeight: ((p.isAlerting && !p.isAlertingSilenced) ? theme.typography.fontWeightBold : undefined),
        // color: ((p.isAlerting && !p.isAlertingSilenced) ? theme.palette.secondary.main : undefined),
        fontSize: theme.typography.h5.fontSize,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2)
    })
}))

const AlertingProductListCard: React.FC<AlertingProductListCardProps> = ({ product, isAlerting, isAlertingSilenced, onSilenceToggle }) => {
    const classes = useStyles({ isAlerting, isAlertingSilenced })

    const handleToggle = (e: React.MouseEvent<any, any>) => {
        e.stopPropagation()
        onSilenceToggle()
    }

    return <ListCard
        onClick={handleToggle}
        avatar={<StoreAvatar store={product.store} />}
        middleContent={<ProductDetailsBlock product={product} />}
        rightContent={<Box style={{ display: 'flex' }}>
            <IconButton onClick={handleToggle}>
                {isAlerting && !isAlertingSilenced && <IconAlerting color="secondary" fontSize="large" />}
                {isAlertingSilenced && <IconAlertingSilenced fontSize="large" />}
            </IconButton>
            <PPUText className={classes.ppu} isGoodPrice={false} value={product.pricePerUnit} />
        </Box>}
    />
}

export default AlertingProductListCard