import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles'

export interface ErrorSnackBarProps {
    error: string
}

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
}));

export default function ErrorSnackBar(props: ErrorSnackBarProps) {
    const { error } = props
    const classes = useStyles();
    const hasError = error !== ""
    const [dismissed, setDismissed] = useState(false)
    console.log('admiral snackbar', props, dismissed)
    const closeSnackbar = () => setDismissed(true)
    return <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        open={hasError && !dismissed}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{props.error}</span>}
        action={[
            <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={closeSnackbar}
            >
                <CloseIcon />
            </IconButton>,
        ]}
    />
}