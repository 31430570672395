"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Level;
(function (Level) {
    Level[Level["DEBUG"] = 1] = "DEBUG";
    Level[Level["INFO"] = 2] = "INFO";
    Level[Level["WARN"] = 3] = "WARN";
    Level[Level["ERROR"] = 4] = "ERROR";
})(Level = exports.Level || (exports.Level = {}));
var defaultLevel = Level.INFO;
function setDefaultLevel(level) {
    defaultLevel = level;
}
exports.setDefaultLevel = setDefaultLevel;
function loggerFactory(context, level) {
    var currentLevel = level;
    var currentContext = context;
    function doLog(level, args) {
        if ((currentLevel || defaultLevel) > level) {
            return;
        }
        var ctxToAply = currentContext || {};
        // DESIGN DECISION: consider namespacing context, but I like it not being nested
        var base = __assign({}, ctxToAply);
        for (var _i = 0, args_1 = args; _i < args_1.length; _i++) {
            var arg = args_1[_i];
            if (typeof arg == 'string') {
                base.msg = arg;
            }
            else {
                Object.assign(base, arg);
            }
        }
        console.log(JSON.stringify(Object.assign(base, { level: Level[level] })));
    }
    function log() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        doLog(Level.INFO, args);
    }
    log.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        doLog(Level.INFO, args);
    };
    log.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        doLog(Level.WARN, args);
    };
    log.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        doLog(Level.ERROR, args);
    };
    log.debug = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        doLog(Level.DEBUG, args);
    };
    log.setLevel = function (level) { return currentLevel = level; };
    log.getLevel = function () { return currentLevel || defaultLevel; };
    log.withContext = function (context) {
        return loggerFactory(__assign(__assign({}, currentContext), context), currentLevel);
    };
    return log;
}
function logger(context) {
    return loggerFactory(context);
}
exports.logger = logger;
var GLOBAL_LOGGER = loggerFactory();
exports.default = GLOBAL_LOGGER;
