import React from 'react'
import StarsIcon from '@material-ui/icons/StarTwoTone'
import { Product } from './types'
import { Typography } from '@material-ui/core'
import { displayPerUnit } from './util'

export interface PPUDisplayProps {
    className?: string,
    value: Product["pricePerUnit"],
    isGoodPrice: boolean
}

export function PPUText(props: PPUDisplayProps) {
    return <Typography className={props.className}>
        {props.isGoodPrice && <StarsIcon fontSize="inherit" />}
        {displayPerUnit(props.value)}
    </Typography>
}
