"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
// Builder pattern for creating a composite set of filters
// supports loading and exporting
var FilterBuilder = /** @class */ (function () {
    function FilterBuilder(filter) {
        var _this = this;
        this.state = {};
        // removes a particular filter from the configuration, if it was even present
        this.removeFilter = function (filterKey) {
            delete _this.state[filterKey];
            return _this;
        };
        // If we get a "function lacks ending return type and..." compile error, that means we are missing a case in the switch statement
        this.with = function (type, options) {
            var fo = { options: options, type: type };
            switch (fo.type) {
                case 'byPPU':
                    _this.state[fo.type] = __assign(__assign({}, fo), { filter: _1.byPPU(fo.options) });
                    return _this;
                case 'byUnit':
                    _this.state[fo.type] = __assign(__assign({}, fo), { filter: _1.byUnit(fo.options) });
                    return _this;
                case 'byTextSimilarity':
                    _this.state[fo.type] = __assign(__assign({}, fo), { filter: _1.byTextSimilarity(fo.options) });
                    return _this;
                case 'includesWords':
                    _this.state[fo.type] = __assign(__assign({}, fo), { filter: _1.includesWords(fo.options) });
                    return _this;
                case 'excludesWords':
                    _this.state[fo.type] = __assign(__assign({}, fo), { filter: _1.excludesWords(fo.options) });
                    return _this;
                case 'excludeStoreIDs':
                    _this.state[fo.type] = __assign(__assign({}, fo), { filter: _1.excludeStoreIDs(fo.options) });
                    return _this;
                case 'excludeProductIDs':
                    _this.state[fo.type] = __assign(__assign({}, fo), { filter: _1.excludeProductIDs(fo.options) });
                    return _this;
            }
        };
        // Builds a composite filter using the current configuration
        this.build = function () {
            // copy filter functions so we can use that in a closure
            var allFilters = [];
            for (var k in _this.state) {
                allFilters.push(_this.state[k].filter);
            }
            // We shouldn't use 'this' in here or the filter will mutate with the builder
            var compositeFilter = function (p) {
                for (var _i = 0, allFilters_1 = allFilters; _i < allFilters_1.length; _i++) {
                    var f = allFilters_1[_i];
                    if (!f(p)) {
                        return false;
                    }
                }
                return true;
            };
            return compositeFilter;
        };
        // Loads the configuration into the builder
        this.load = function (opts) {
            opts.forEach(function (o) { return _this.with(o.type, o.options); });
            return _this;
        };
        // Exports the builder's configuration
        this.export = function () {
            var opts = [];
            for (var k in _this.state) {
                var o = {
                    options: __assign({}, _this.state[k].options),
                    type: _this.state[k].type,
                };
                opts.push(o);
            }
            return opts;
        };
        if (filter) {
            if (filter.excludes) {
                this.with('excludesWords', { words: filter.excludes.join(' ') });
            }
            if (filter.excludeStoreIDs) {
                this.with('excludeStoreIDs', {
                    ids: filter.excludeStoreIDs,
                });
            }
            if (filter.excludeProductIDs) {
                this.with('excludeProductIDs', {
                    ids: filter.excludeProductIDs,
                });
            }
            if (filter.includes) {
                this.with('includesWords', { words: filter.includes.join(' ') });
            }
            if (filter.unit) {
                this.with('byUnit', { unit: filter.unit });
            }
            if (filter.maxPricePerUnit || filter.minPricePerUnit) {
                this.with('byPPU', {
                    lower: filter.minPricePerUnit || 0,
                    upper: filter.maxPricePerUnit || Number.MAX_SAFE_INTEGER,
                });
            }
        }
    }
    return FilterBuilder;
}());
exports.FilterBuilder = FilterBuilder;
