import React, { useReducer, useEffect, useState } from 'react'
import Page, { Background } from '../page'
import MenuIcon from '@material-ui/icons/Menu'
import { Paper, Fab, Box, Container, Toolbar, makeStyles, Theme, createStyles, Drawer, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { Route, Switch, useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { reducer as filterReducer, FilterReducerState, FilterReducerAction } from '../../reducers/filter'
import { reducer as filteredProductsReducer, FilteredProductsReducerState, FilteredProductsReducerAction, defaultState as defaultFilteredProductsState } from '../../reducers/filteredProducts'
import { ExtractQueryResultType, ExtractMutationResultType } from '../../lib/typeHelpers'
import { SearchProductsQueryResult, SearchProductsQueryVariables, SavedKeywordsQueryResult, SavedKeywordsQueryVariables, FilterConfigSaveMutationResult, FilterConfigSaveMutationVariables, MutationDeleteFilterArgs, SaveAlertMutationResult, SaveAlertMutationVariables, MutationDeleteAlertArgs } from '@grocery/graphql/dist/client/gen-types'
import { Without } from '../../lib/Without'
import LazyScrollableProductList from '../../components/LazyScrollableProductList'
import { useApolloClient } from '../../hooks/useApolloClient'
import { SearchProductsQuery } from '@grocery/graphql/dist/client/queries/ProductQuery'
import AlertingControlPanel from './AlertingControlPanel'
import SearchInput from '../../components/SearchInput'
import { SavedKeywordsQuery } from '@grocery/graphql/dist/client/queries/SavedKeywords'
import ErrorSnackBar from '../../components/ErrorSnackBar'
import MainMenu from '../../components/MainMenu'
import SplashImage from '../grocery-splash.jpg'
import { FilterConfigSaveMutation } from '@grocery/graphql/dist/client/queries/FilterConfigSaveMutation'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import FilterIcon from '@material-ui/icons/FilterList'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { FilteringControlPanel } from './FilteringControlPanel'
import DeleteIcon from '@material-ui/icons/Delete'
import { DeleteFilter } from '@grocery/graphql/dist/client/queries/DeleteFilter'
import { SaveAlertMutation } from '@grocery/graphql/dist/client/queries/SaveAlertMutation'
import { DeleteAlert } from '@grocery/graphql/dist/client/queries/DeleteAlert'
import { ProductAlert } from './types'
import { ProductListItemProduct } from '../../transformers/flatten_store_product'
import ProductListCard from '../../components/lists/product/ProductListCard'
import AlertingProductListCard from '../../components/lists/product/alerting/AlertingProductListCard'
import isEqual from 'lodash.isequal'

type ApolloSearchResult = ExtractQueryResultType<SearchProductsQueryResult>
type Results = ApolloSearchResult['search']['storeResults']
type FilterDTO = Without<ApolloSearchResult['getFilter'], '__typename'>

type PageState = {
    searching: boolean
    savingFilter: boolean
    previouslySearchedKeywords: string[],
    alertDirty: boolean
    savedAlert?: ProductAlert,
    currentAlert?: ProductAlert,
    error: string
} & FilterReducerState & FilteredProductsReducerState

type Action =
    | { type: 'search.inprogress' }
    | { type: 'search.results', results: Results, filter: FilterDTO, keywords: string, alert?: ProductAlert }
    | { type: 'search.error', error: string }
    | { type: 'error', message: string }
    | { type: 'filter.saving.inprogress' }
    | { type: 'filter.saving.error', error: string }
    | { type: 'filter.saving.success', }
    | { type: 'alert.changed', alert: ProductAlert }
    | { type: 'alert.reset' }
    | { type: 'alert.toggleRow', row: number }
    | { type: 'alert.delete' }
    | { type: 'alert.saved', alert: ProductAlert }
    | { type: 'selection.toggleRow', selected: number }
    | { type: 'selection.cancel' }
    | { type: 'selection.delete' }
    | { type: 'previouskeywords.loaded', keywords: string[] }
    | FilterReducerAction
    | FilteredProductsReducerAction

const searchResultsHandler = (state: PageState, action: { type: 'search.results', results: Results, filter: FilterDTO, keywords: string, alert?: ProductAlert }): PageState => {
    const fs = filterReducer(state, { type: 'filter.load', filter: action.filter })
    const newProductsState = filteredProductsReducer({ ...state, ...fs }, { ...action, type: 'results.load' })
    console.log('results loaded')
    console.dir(action)
    const savedAlert = action.alert ? { ...action.alert } : undefined
    const currentAlert = action.alert ? { ...action.alert } : undefined
    return { ...state, ...newProductsState, searching: false, loadedFilter: action.filter, changedFilter: action.filter, savedAlert, currentAlert, alertDirty: (action.alert === undefined) }
}

const reducer = (state: PageState, action: Action): PageState => {
    console.log('action', action)
    console.dir(state)
    switch (action.type) {
        case 'alert.saved':
            return { ...state, savedAlert: { ...action.alert }, alertDirty: false }
        case 'alert.delete':
            const { currentAlert, savedAlert, ...noAlertState } = state
            return { ...noAlertState, alertDirty: false }
        case 'alert.reset':
            if (!state.savedAlert) {
                // We don't reset if there is no saved alert
                return state
            }
            return { ...state, currentAlert: { ...state.savedAlert }, alertDirty: false }
        case 'alert.changed':
            const thresholdChanged = (state.savedAlert?.alertValueThreshold !== action.alert.alertValueThreshold)
            const savedSilencedIDs = state.savedAlert?.silencedProductIDs ?? []
            const localSilencedIDs = action.alert.silencedProductIDs ?? []
            const silencedProductIDsChanged = !isEqual(savedSilencedIDs, localSilencedIDs)
            return { ...state, currentAlert: { ...action.alert }, alertDirty: thresholdChanged || silencedProductIDsChanged }
        case 'previouskeywords.loaded':
            return { ...state, previouslySearchedKeywords: action.keywords }
        // TODO: Use a combine reducer for cleaner code
        case 'filter.changed':
        case 'filter.reset':
        case 'filter.load':
            const filterState = filterReducer(state, action)
            const productsState = filteredProductsReducer({ ...state, ...filterState }, action)
            return { ...state, ...filterState, ...productsState }
        case 'search.inprogress':
            return { ...state, searching: true, error: '' }
        case 'search.results':
            return searchResultsHandler(state, action)
        case 'search.error':
            return { ...state, searching: false, error: action.error }
        case 'error':
            return { ...state, error: action.message }
        case 'filter.saving.error':
            return { ...state, error: action.error, savingFilter: false }
        case 'filter.saving.success':
            return { ...state, savingFilter: false, filterDirty: false, loadedFilter: state.changedFilter }
        case 'filter.saving.inprogress':
            return { ...state, savingFilter: true }
        default:
            return state
    }
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    menuFab: {
        // margin: 10,
        backgroundColor: theme.palette.background.paper,
    },
    speedDial: {
        position: 'absolute',
        '&.MuiSpeedDial-directionUp': {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        '& button': {
            '&.MuiSpeedDial-fab': {
                backgroundColor: theme.palette.secondary.main,
            },
        }
    },
    menuTop: {
        height: 100,
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2),
        },
        [theme.breakpoints.up('xl')]: {
            margin: theme.spacing(5),
        },
    },
    appBarTitle: {
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
        },
    },
    chrome: {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${SplashImage})`,
        backgroundColor: `${theme.palette.primary.light}`,
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        overflow: 'auto',
    },
}))

const initialState: PageState = {
    error: '',
    searching: false,
    previouslySearchedKeywords: [],
    savingFilter: false,
    filterDirty: false,
    alertDirty: false,
    ...defaultFilteredProductsState,
}

const BASE_URL = '/search'

const ResultsPage: React.FC<{}> = props => {
    const classes = useStyles()
    const history = useHistory()
    const params = useParams<{ keywords?: string }>()
    const [{ searching, filteredProducts: products,
        changedFilter: filter, filterDirty, savingFilter, previouslySearchedKeywords,
        currentAlert, savedAlert, alertDirty }, dispatch] = useReducer(reducer, initialState)
    const goodPPUValueThreshold = filter?.goodPPUValueThreshold
    const [open, setOpen] = React.useState(false)
    const keywords = params.keywords || ''
    const fullScreen = !keywords
    const client = useApolloClient()

    const alertingPanelOpen = useRouteMatch(`${BASE_URL}/:keywords/alerting`)
    const filteringPanelOpen = useRouteMatch(`${BASE_URL}/:keywords/filtering`)

    useEffect(() => {
        const doSearch = async (keywords: string) => {
            try {
                if (keywords.length < 3) {
                    dispatch({ type: 'search.error', error: 'Search keywords must have at least 3 characters.' })
                    return
                }

                dispatch({ type: 'search.inprogress' })
                const { data, errors } = await client.query<ExtractQueryResultType<SearchProductsQueryResult>, SearchProductsQueryVariables>({ query: SearchProductsQuery, variables: { keywords }, fetchPolicy: 'no-cache' })
                if (errors) {
                    dispatch({ type: 'search.error', error: errors.toString() })
                    return
                }

                if (data.search) {
                    const { __typename, ...newFilter } = data.getFilter
                    dispatch({ type: 'search.results', results: data.search.storeResults, filter: newFilter, keywords, alert: data.productAlert ?? undefined })
                }

            } catch (err) {
                dispatch({ type: 'search.error', error: `${err}` })
            }

        }
        if (keywords) {
            doSearch(keywords)
        }
    }, [keywords, client, dispatch])

    // Load previous searched keywords
    useEffect(() => {
        async function doFetch() {
            const { data, errors } = await client.query<ExtractQueryResultType<SavedKeywordsQueryResult>, SavedKeywordsQueryVariables>({ query: SavedKeywordsQuery, variables: {}, fetchPolicy: 'no-cache' })
            if (errors) {
                dispatch({ type: 'error', message: errors.map(e => e.message).join('\n') })
            }
            dispatch({ type: 'previouskeywords.loaded', keywords: data.savedKeywords })
        }
        doFetch()
    }, [client, dispatch])

    function handleSearchRequest(keywords: string) {
        if (!keywords) {
            history.push('/')
            return
        }
        history.push(`${BASE_URL}/${keywords}`)
    }

    const handleFilterSave = async (filter?: FilterDTO) => {
        if (filter) {
            history.push(`${BASE_URL}/${params.keywords}`)
            const { keywords, ...input } = filter
            try {
                const { data, errors } = await client.mutate<ExtractMutationResultType<FilterConfigSaveMutationResult>, FilterConfigSaveMutationVariables>({
                    variables: { keywords, input },
                    mutation: FilterConfigSaveMutation,
                })
                if (errors) {
                    dispatch({ type: 'filter.saving.error', error: errors.toString() })
                    return
                }

                if (data) {
                    dispatch({ type: 'filter.saving.success' })
                }
            } catch (err) {
                dispatch({ type: 'filter.saving.error', error: `${err}` })
            }
        }
    }

    const handleAlertSave = async () => {
        if (!currentAlert) {
            console.warn("no current alert to save - skipping")
            return
        }
        try {
            const { data } = await client.mutate<ExtractMutationResultType<SaveAlertMutationResult>, SaveAlertMutationVariables>({
                variables: {
                    input: {
                        keywords,
                        ...currentAlert
                    }
                },
                mutation: SaveAlertMutation,
            })
            if (data) {
                dispatch({ type: 'alert.saved', alert: data?.saveAlert })
            }
        } catch (error) {
            dispatch({ type: 'error', message: error.message ?? `${error}` })
        }
    }

    const handleAlertSilenceToggle = async (productID: string) => {
        console.log(`silencing alert for product ${productID}`)
        if (currentAlert) {
            const newAlert: any = currentAlert
            let ids = currentAlert?.silencedProductIDs ?? []
            const currentIndex = ids.indexOf(productID)
            if (currentIndex >= 0) {
                ids = ids.slice(0, currentIndex).concat(ids.slice(currentIndex + 1, ids.length))
            } else {
                ids.push(productID)
            }
            if (newAlert['__typename']) {
                delete newAlert['__typename']
            }
            dispatch({ type: 'alert.changed', alert: { ...newAlert, silencedProductIDs: ids } })
        }
    }

    const handleProductDelete = async (productId: string) => {

        const newFilter = filter ? { ...filter } : {
            keywords: '',
            excludes: [],
            includes: [],
            excludeProductIDs: [],
            excludeStoreIDs: []
        }

        newFilter.excludeProductIDs = newFilter.excludeProductIDs.concat(productId)
        dispatch({ type: 'filter.changed', filter: newFilter })
        await handleFilterSave(newFilter)
    }
    const handleDeleteSearch = async () => {
        const deleteFilterPromise = client.mutate<boolean, MutationDeleteFilterArgs>({
            variables: { keywords },
            mutation: DeleteFilter,
        })

        const results = await Promise.all([deleteFilterPromise, handleDeleteAlert()])
        results.forEach(result => {
            if (result?.errors) {
                dispatch({ type: 'error', message: result.errors.map(e => e.message).join('\n') })
            }
        })
        history.push('/searches')
    }
    const handleDeleteAlert = async () => {
        const result = await client.mutate<boolean, MutationDeleteAlertArgs>({
            variables: { keywords },
            mutation: DeleteAlert,
        })
        if (result.errors) {
            console.error(result.errors)
            return
        }
        dispatch({ type: 'alert.delete' })
        return result
    }
    const [deleteOpen, setDeleteOpen] = useState(false)
    if (fullScreen) {
        return <FullScreenSearch suggestions={previouslySearchedKeywords} onSearchRequest={handleSearchRequest} keywords={keywords} />
    }

    const primaryBarContent = <SearchAppBar suggestions={previouslySearchedKeywords} previousSearch={keywords || ''} disabled={false} onSearchRequest={handleSearchRequest} />

    const goToFiltering = () => {
        dispatch({ type: 'selection.cancel' })
        history.push(`${BASE_URL}/${params.keywords}/filtering`)
    }

    const goToAlerting = () => {
        dispatch({ type: 'selection.cancel' })
        history.push(`${BASE_URL}/${params.keywords}/alerting`)
    }
    const goToSearchResults = () => {
        dispatch({ type: 'selection.cancel' })
        history.replace(`${BASE_URL}/${keywords}`)
    }

    const isGoodPrice = (p: ProductListItemProduct) => (goodPPUValueThreshold ?? 0) > (p.pricePerUnit.price ?? 0)
    const isAlerting = (p: ProductListItemProduct) => (p.pricePerUnit.price ?? 0) <= (currentAlert?.alertValueThreshold ?? -1)
    const isAlertingSilenced = (p: ProductListItemProduct) => currentAlert?.silencedProductIDs?.includes(p.id) ?? false
    const renderProductRow = (index: number): [JSX.Element, string] => {
        // TODO: probs make this nicer, but should work
        let rowKey: string
        let element: JSX.Element
        if (alertingPanelOpen) {
            rowKey = products[index].id + 'alerting'
            element = <AlertingProductListCard
                product={products[index]}
                isAlerting={isAlerting(products[index])}
                isAlertingSilenced={isAlertingSilenced(products[index])}
                onSilenceToggle={() => handleAlertSilenceToggle(products[index].id)}
            />
        } else {
            element = <ProductListCard
                product={products[index]}
                isGoodPrice={isGoodPrice(products[index])}
                onDelete={() => handleProductDelete(products[index].id)}
            />
            rowKey = products[index].id
        }
        return [element, rowKey]
    }

    return <Page loading={searching} topBarContent={primaryBarContent} >
        <Dialog onClose={() => setDeleteOpen(false)} aria-labelledby="delete-confirm-dialog" open={deleteOpen}>
            <DialogTitle id="delete-search-confirm">Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Are you sure you want to delete the search <strong>{keywords}</strong>?</DialogContentText>
                <DialogContentText><FilterIcon /><strong>Filtering</strong> will be deleted</DialogContentText>
                <DialogContentText><NotificationsIcon /><strong>Alerting</strong> (your) alerts are deleted</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDeleteOpen(false)} color="primary">
                    Cancel
          </Button>
                <Button onClick={handleDeleteSearch} color="secondary" autoFocus>
                    Delete
          </Button>
            </DialogActions>
        </Dialog>
        {/* Results List */}
        <Paper square={false} style={{ display: 'flex', flex: 1 }}>
            <LazyScrollableProductList
                renderRow={renderProductRow}
                rowHeightPx={76}
                numProducts={products.length}
            />
        </Paper>

        {/* Control Panel */}
        <Switch>

            <Route exact={true} path={`${BASE_URL}/:keywords/filtering`}>
                {/* FILTERING CONTROL */}
                <FilteringControlPanel
                    dirty={filterDirty}
                    saving={savingFilter}
                    onReset={() => dispatch({ type: 'filter.reset' })}
                    onDismiss={goToSearchResults}
                    onSave={async () => handleFilterSave(filter)}
                    config={filter}
                    onConfigChanged={(config) => dispatch({ type: 'filter.changed', filter: { keywords, ...config } })} />
            </Route>
            <Route exact={true} path={`${BASE_URL}/:keywords/alerting`}>
                {/* ALERTING CONTROL */}
                <AlertingControlPanel dirty={alertDirty} saving={false}
                    onDismiss={goToSearchResults}
                    onReset={() => dispatch({ type: 'alert.reset' })}
                    showReset={!!savedAlert}
                    onSave={handleAlertSave}
                    onDelete={handleDeleteAlert}
                    onChange={settings => {
                        dispatch({ type: 'alert.changed', alert: { alertValueThreshold: settings.alertValueThreshold } })
                    }}
                    alert={currentAlert}
                />
            </Route>
            <Route exact={true} path={`${BASE_URL}/:keywords`}>
                {!alertingPanelOpen && !filteringPanelOpen && <SpeedDial
                    className={classes.speedDial}
                    ariaLabel="Actions"
                    icon={<SpeedDialIcon />}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    direction="up"
                >
                    <SpeedDialAction
                        key={'Filter'}
                        icon={<FilterIcon />}
                        tooltipTitle={'Configure search filter'}
                        onClick={goToFiltering}
                    />
                    <SpeedDialAction
                        key={'Alerting'}
                        icon={<NotificationsIcon />}
                        tooltipTitle={'Configure your search alerts'}
                        onClick={goToAlerting}
                    />
                    <SpeedDialAction
                        key={'Delete'}
                        icon={<DeleteIcon />}
                        tooltipTitle={'Delete this search'}
                        onClick={() => setDeleteOpen(true)}
                    />

                </SpeedDial>}
            </Route>
        </Switch>
    </Page >
}


function FullScreenSearch(props: { keywords: string, suggestions: string[], onSearchRequest: (keywords: string) => void }) {
    const classes = useStyles()
    const [menuOpen, setMenuOpen] = useState(false)
    return <Background dark={false}>
        <ErrorSnackBar error={""} />
        <Drawer
            anchor="left"
            open={menuOpen}
            onClose={() => setMenuOpen(!menuOpen)}
        >
            <MainMenu />
        </Drawer>
        <Box className={classes.menuTop}>
            <Fab onClick={() => setMenuOpen(!menuOpen)} color="inherit" aria-label="add" className={classes.menuFab}>
                <MenuIcon color="inherit" />
            </Fab>
        </Box>
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Container maxWidth="md">
                <Box mt={10}>
                    <Paper elevation={10}>
                        <Toolbar>
                            <SearchInput disabled={false} previousSearch={props.keywords} getSuggestions={keywords => props.suggestions.filter(s => s.includes(keywords))} onChange={props.onSearchRequest} />
                        </Toolbar>
                        {/*  TODO: Attribution for 'Photo by nrd on Unsplash' */}
                    </Paper>
                </Box>
            </Container>
        </div>
    </Background>
}

function SearchAppBar(props: { previousSearch: string, suggestions: string[], disabled: boolean, onSearchRequest: (kw: string) => void }) {
    return <SearchInput disabled={props.disabled} previousSearch={props.previousSearch} getSuggestions={keywords => props.suggestions.filter(s => s.includes(keywords))} onChange={keywords => props.onSearchRequest(keywords)} />
}

export default ResultsPage