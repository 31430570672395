import React from 'react'
import { styled, Typography } from '@material-ui/core'
import { Product } from './types'

export const ProductTitle = styled(props => <Typography {...props} variant="h5" component="h2" />)
    (({ theme }) => ({
        verticalAlign: 'middle',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle1.fontSize,
        }
    }))

export const ProductSubtitle = styled(props => <Typography {...props} variant="subtitle1" component="h3" />)
    (({ theme }) => ({
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
        }
    }))

const ProductDetailsBlock: React.FC<{ product: Product }> = ({ product }) => {
    return <>
        <ProductTitle noWrap={true}>
            {product.name}
        </ProductTitle>
        <ProductSubtitle >
            {(product.sizeInfo.unitAmount ? product.sizeInfo.unitAmount.toFixed(2) : '?') + ' ' + product.sizeInfo.unit}
        </ProductSubtitle>
    </>
}

export default ProductDetailsBlock