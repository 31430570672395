import React, { ReactElement } from 'react'

import { useAuth, UserContext } from '../contexts/AuthContext'

export default (props: { children: ReactElement | ReactElement[] }) => {
  const [state, signIn] = useAuth()

  // Initializing
  if (state.initializing) {
    return <div>Loading...</div>
  }

  // Logged in
  if (state.user) {
    return (
      <UserContext.Provider value={state}>
        {props.children}
      </UserContext.Provider>
    )
  }

  // Not logged in
  return (
    <div className="wrapper">
      <div className="content">
        <h1>Groceries</h1>
        <p>Groceries App</p>
        <button onClick={signIn}>Sign in with Google</button>
      </div>
    </div>
  )
}
