import {Store, SearchProductsQueryResult } from "@grocery/graphql/dist/client/gen-types"
import { ExtractQueryResultType } from '../lib/typeHelpers'

type ApolloSearchResult = ExtractQueryResultType<SearchProductsQueryResult>

export type Results = ApolloSearchResult['search']['storeResults']

export interface ProductListItemProduct {
    id: string
    name: string
    brand?: string | null
    store: Store
    description: string
    price: number
    sizeInfo: {
        unit: string
        unitAmount?: number | null
    }
    pricePerUnit: {
        price?: number | null
        unit: string
    }
    productURL?: string | null
    productImageURL?: string | null
}

export function resultsToProductList(results?: Results): {
    products: Array<ProductListItemProduct>
} & { minPPU?: number, maxPPU?: number } {
    let minPPU = 0, maxPPU = 0.01
    if (!results) {
        return { products: [] }
    }

    // Flatten search results into list products
    const products: ProductListItemProduct[] = []
    results.forEach(i => {
        i.products.forEach(p => {
            if (p.pricePerUnit && p.pricePerUnit.price) {
                if (maxPPU < p.pricePerUnit.price) {
                    maxPPU = p.pricePerUnit.price
                }
                if (minPPU > p.pricePerUnit.price) {
                    minPPU = p.pricePerUnit.price
                }
            }
            products.push({ ...p, store: i.store })
        })
    })

    // Sort by PPU
    const sortedProducts = products.sort((a, b) => (a.pricePerUnit.price ? a.pricePerUnit.price : 0) - (b.pricePerUnit.price ? b.pricePerUnit.price : 0))

    return { products: sortedProducts, minPPU, maxPPU }
}