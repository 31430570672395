import React, { useState } from 'react'
import { Box, Fab, Drawer, makeStyles, Theme, createStyles } from '@material-ui/core'
import MainMenu from './MainMenu'
import MenuIcon from '@material-ui/icons/Menu'


const useStyles = makeStyles((theme: Theme) => createStyles({
    menuFab: {
        // margin: 10,
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        backgroundColor: theme.palette.primary.light,
    },
    menuTop: {
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
        height: 100,
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2),
        },
        [theme.breakpoints.up('xl')]: {
            margin: theme.spacing(5),
        },
    },
}))

export default function MainMenuFab() {
    const classes = useStyles()
    const [mainMenuDrawerOpen, toggleMenu] = useState(false)

    return <>
        <Box className={classes.menuTop}>
            <Fab onClick={() => toggleMenu(!mainMenuDrawerOpen)} color="inherit" aria-label="add" className={classes.menuFab}>
                <MenuIcon color="inherit" />
            </Fab>
        </Box>
        <Drawer
            anchor="left"
            open={mainMenuDrawerOpen}
            onClose={() => toggleMenu(false)}
        >
            <MainMenu />
        </Drawer>
    </>
}