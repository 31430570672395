"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var xlsx_1 = require("xlsx");
var json_1 = require("./json");
var errors_1 = require("../errors");
var __1 = require("..");
var workbookNameToPurchaseDateRegex = /(\d{1,2})-(\d{1,2})-(\d{1,4})/;
exports.parseReceipt = function (workbookName, data) {
    // Ignore things like the template files
    if (!workbookName.match(workbookNameToPurchaseDateRegex)) {
        console.info("Ignoring " + workbookName + " because it doesn't have a date so it probably isn't a receipts file.");
        return;
    }
    var store = '';
    var wbNameParts = workbookName.split('_');
    if (wbNameParts.length > 1) {
        store = wbNameParts[1].replace('-', ' ').replace(/\.\S+$/, '');
    }
    var created = parseDate(workbookName);
    if (created === undefined) {
        throw new errors_1.ReceiptParseError(workbookName, 'unable to parse created date');
    }
    var workbook = xlsx_1.read(data, { type: 'buffer' });
    __1.log('reading workbook', { workbookName: workbookName });
    var itemsWorksheet = workbook.Sheets['Items'] || workbook.Sheets['Sheet1'];
    if (!itemsWorksheet) {
        throw new errors_1.ReceiptParseError(workbookName, "unable to find 'Items' sheet");
    }
    var itemsJSON = xlsx_1.utils.sheet_to_json(itemsWorksheet);
    __1.log('found items', { numItems: itemsJSON.length });
    var totalsWorksheet = workbook.Sheets['Totals'] ||
        workbook.Sheets['Sheet2'] ||
        workbook.Sheets['Total'];
    if (!totalsWorksheet) {
        throw new errors_1.ReceiptParseError(workbookName, "unable to find 'Totals' sheet");
    }
    var totalsJSON = xlsx_1.utils.sheet_to_json(totalsWorksheet);
    return json_1.jsonToReceipt(workbookName, store, created, itemsJSON, totalsJSON);
};
var parseDate = function (str) {
    var result = workbookNameToPurchaseDateRegex.exec(str);
    if (result) {
        var monthStr = result[1], dayStr = result[2], yearStr = result[3];
        var _a = [
            parseInt(monthStr),
            parseInt(dayStr),
            parseInt(yearStr),
        ], month = _a[0], day = _a[1], year = _a[2];
        var d = new Date(year + 2000, month - 1, day);
        if (d > new Date()) {
            d.setFullYear(year + 1900);
        }
        return d;
    }
    return undefined;
};
