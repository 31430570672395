import React from "react";
import Downshift from "downshift";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";

export interface SuggestionProps {
    highlightedIndex: number | null,
    index: number,
    itemProps: any,
    selectedItem: string,
    suggestion: string
}

function renderSuggestion(suggestionProps: SuggestionProps) {
    const {
        suggestion,
        index,
        itemProps,
        highlightedIndex,
        selectedItem
    } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || "").indexOf(suggestion) > -1;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400
            }}
        >
            {suggestion}
        </MenuItem>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    container: {
        flexGrow: 1,
        position: "relative"
    },
    paper: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
    chip: {
        margin: theme.spacing(0.5, 0.25)
    },
    inputRoot: {
        color: "inherit",
        flexWrap: "wrap"
    },
    inputInput: {
        width: "auto",
        flexGrow: 1
    }
}));

export interface AutoSuggestInputProps {
    placeholder: string
    getSuggestions: (value: string | null) => string[]
    value: string
    onChange?: (value: string) => void
    onSubmit?: () => void
    suggestEnabled: boolean
    disabled?: boolean
}

export default function AutoSearchInput(props: AutoSuggestInputProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Downshift id="downshift-simple" selectedItem={props.value} onInputValueChange={props.onChange}>
                {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    selectedItem
                }) => {
                    const { ref, onChange, color, ...rest } = getInputProps({
                        placeholder: props.placeholder,
                    });

                    return (
                        <div className={classes.container}>
                            <TextField
                                InputProps={{
                                    ...rest,
                                    disabled: props.disabled,
                                    inputRef: ref as any,
                                    classes: {
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    },
                                    onChange: onChange as any,
                                    fullWidth: true,
                                    type: 'search',
                                    onKeyPress: (ev) => {
                                        if (ev.key === 'Enter') {
                                            ev.preventDefault();
                                            props.onSubmit && props.onSubmit()
                                        }
                                    }
                                }}

                                InputLabelProps={getLabelProps()}
                                fullWidth
                            />

                            <Paper className={classes.paper} square {...getMenuProps()}>
                                {isOpen && props.suggestEnabled ? (
                                    props.getSuggestions(inputValue).map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem
                                        })
                                    )
                                ) : null}
                            </Paper>
                        </div>
                    );
                }}
            </Downshift>
        </div>
    );
}
