import { useState, useEffect } from 'react';
import { useSession } from '../contexts/AuthContext'
import ApolloClient from 'apollo-boost'
import config from '../config'

export function useApolloClient() {
    const user = useSession()
    // TODO: Set Apollo Client Cache Type
    const [client, setClient] = useState<ApolloClient<any>>(
        createClientForUser(user)
    );

    useEffect(() => {
        const userClient = createClientForUser(user);
        setClient(userClient)
    }, [user]);

    return client;
}

function createClientForUser(user: firebase.User | null) {
    console.log('Creating a new apollo client')
    return new ApolloClient({
        uri: config.api.URL,
        request: async (operation) => {
            // Set the auth header using Google JWT for every operation
            operation.setContext({
                headers: {
                    authorization: `Bearer ${user ? await user.getIdToken() : ''}`
                }
            });
        }
    })
}
