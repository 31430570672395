import { useContext, createContext, useEffect, useState } from 'react'

import firebase from '../lib/auth/firebase'

export const UserContext = createContext<AuthState>({
  user: null,
  initializing: true,
})

export const useSession = () => {
  const { user } = useContext(UserContext)

  return user
}

export interface AuthState {
  initializing: boolean
  user: firebase.User | null
}

export const useAuth = (): [
  AuthState,
  () => Promise<void>,
  () => Promise<void>,
] => {
  const [state, setState] = useState<AuthState>(() => {
    const user = firebase.auth().currentUser
    return { initializing: !user, user }
  })

  function onChange(user: firebase.User | null) {
    setState({ initializing: false, user })
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  const signIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    await firebase.auth().signInWithPopup(provider)
    return
  }

  const signOut = async () => {
    return firebase.auth().signOut()
  }

  return [state, signIn, signOut]
}
