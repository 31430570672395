import React from 'react'
import LazyList from './LazyList'
import AutoSizer from 'react-virtualized-auto-sizer'

export interface LazyScrollableProductListProps {
    rowHeightPx: number
    numProducts: number
    renderRow: (index: number) => [JSX.Element, any]
}

export default function (props: LazyScrollableProductListProps) {
    return <AutoSizer>
        {({ height, width }) => (
            <LazyList
                rowRenderer={props.renderRow}
                height={height}
                width={width}
                minRowHeight={props.rowHeightPx}
                overscan={20}
                totalRows={props.numProducts}
            />
        )}
    </AutoSizer>
}
