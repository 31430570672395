import { ExtractQueryResultType } from './lib/typeHelpers'
import { GetFilterQueryResult } from '@grocery/graphql/dist/client/gen-types'
import { Without } from './lib/Without'
import { ProductListItemProduct } from './transformers/flatten_store_product'
import { FilterBuilder } from '@grocery/common/dist/filter'
import { BaseUnit } from '@grocery/common/dist/model'

export type FilterConfig = Without<ExtractQueryResultType<GetFilterQueryResult>['getFilter'], '__typename'>

export function filterProducts(products: ProductListItemProduct[], config: FilterConfig) {
    if (products.length < 1) {
        return []
    }

    const fb = new FilterBuilder()
        .with('excludesWords', { words: config.excludes.join(' ') })
        .with('includesWords', { words: config.includes.join(' ') })
    if (config.unit) {
        fb.with('byUnit', { unit: BaseUnit[config.unit] })
    }
    if (config.excludeProductIDs) {
        fb.with('excludeProductIDs', { ids: config.excludeProductIDs })
    }
    if (config.maxPricePerUnit) {
        const lower = config.minPricePerUnit ? config.minPricePerUnit : 0
        const upper = config.maxPricePerUnit
        fb.with('byPPU', { lower, upper })
    }
    const filter = fb.build()

    // TODO: Remove this usage of 'any' with something more type safe
    return products.filter(p => filter(p as any))
}