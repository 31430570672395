interface Config {
    api: {
        URL: string
    }
}

const PRODUCTION: Config = {
    api: {
        URL: 'https://grassypoint.herokuapp.com/graphql'
    }
}

const DEV: Config = {
    api: {
        URL: 'http://localhost:3000/graphql'
    }
}

// DEFAULT: DEV
const config = process.env.REACT_APP_STAGE === 'prod'
    ? PRODUCTION
    : DEV;

export default {
    ...config
}