"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var string_similarity_1 = require("string-similarity");
var builder_1 = require("./builder");
exports.FilterBuilder = builder_1.FilterBuilder;
// filters products by word similarty score
exports.byTextSimilarity = function (_a) {
    var text = _a.text, _b = _a.threshold, threshold = _b === void 0 ? 0 : _b;
    return function (p) {
        return (string_similarity_1.compareTwoStrings(text, p.name + " " + p.brand + " " + p.description) > threshold);
    };
};
// filters products that don't have the specified unit
// (in PPU, because anything with size info should have PPU anyway)
exports.byUnit = function (_a) {
    var unit = _a.unit;
    return function (p) {
        return p.pricePerUnit.unit == unit;
    };
};
// filters out any products that have ppu.price <= lower >= upper
exports.byPPU = function (_a) {
    var lower = _a.lower, upper = _a.upper;
    return function (p) {
        return (!!p.pricePerUnit.price &&
            (p.pricePerUnit.price >= lower && p.pricePerUnit.price <= upper));
    };
};
var defaultWordFilterOptions = {
    useName: true,
    useBrand: true,
    useDescription: true,
    separator: ' ',
};
// filters out any products that DON'T have the specified words in the name
exports.includesWords = function (_a) {
    var words = _a.words, _b = _a.settings, settings = _b === void 0 ? defaultWordFilterOptions : _b;
    return function (p) {
        if (words.length > 0) {
            var text = getTextForFilter(settings, p);
            for (var _i = 0, _a = words.toLowerCase().split(settings.separator); _i < _a.length; _i++) {
                var word = _a[_i];
                if (!text.includes(word.toLowerCase())) {
                    return false; // filter OUT
                }
            }
        }
        return true;
    };
};
// filters out any products that DO have the specified words in the name
exports.excludesWords = function (_a) {
    var words = _a.words, _b = _a.settings, settings = _b === void 0 ? defaultWordFilterOptions : _b;
    return function (p) {
        if (words.length > 0) {
            var text = getTextForFilter(settings, p);
            for (var _i = 0, _a = words.toLowerCase().split(settings.separator); _i < _a.length; _i++) {
                var word = _a[_i];
                if (text.includes(word.toLowerCase())) {
                    return false; // filter OUT
                }
            }
        }
        return true;
    };
};
// filters out any products that have a product ID in this list
exports.excludeProductIDs = function (_a) {
    var ids = _a.ids;
    return function (p) {
        return !ids.includes(p.id);
    };
};
// filters out any products that have a store ID in this list
exports.excludeStoreIDs = function (_a) {
    var ids = _a.ids;
    return function (p) {
        return !ids.includes(p.storeId);
    };
};
function getTextForFilter(opts, p) {
    return ((opts.useName ? p.name : '') + (opts.useBrand ? p.brand : '')).toLowerCase();
}
