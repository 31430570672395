"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var index_1 = require("./index");
var convert = require("convert-units");
function toBaseUnit(unitAmount, unit) {
    // Handle special cases
    switch (unit.toLowerCase()) {
        case 'ea':
        case 'each':
        case 'unit':
        case 'units':
        case 'case':
        case 'pack':
            return { unit: index_1.BaseUnit.UNITS, unitAmount: unitAmount };
    }
    try {
        var desc = convert().describe(unit);
        switch (desc.measure) {
            case 'mass':
                return {
                    unit: index_1.BaseUnit.KG,
                    unitAmount: convert(unitAmount)
                        .from(unit)
                        .to('kg'),
                };
            case 'volume':
                return {
                    unit: index_1.BaseUnit.L,
                    unitAmount: convert(unitAmount)
                        .from(unit)
                        .to('l'),
                };
            case 'distance':
                return {
                    unit: index_1.BaseUnit.M,
                    unitAmount: convert(unitAmount)
                        .from(unit)
                        .to('m'),
                };
            case 'area':
                return {
                    unit: index_1.BaseUnit.M2,
                    unitAmount: convert(unitAmount)
                        .from(unit)
                        .to('m2'),
                };
            default:
                return { unit: index_1.BaseUnit.UNKNOWN, unitAmount: 1 };
        }
    }
    catch (err) {
        return { unit: index_1.BaseUnit.UNKNOWN, unitAmount: 1 };
    }
}
exports.toBaseUnit = toBaseUnit;
// Supports formats like:
// - 12
// - 12.09
// - 12x355
// - 12 / 355
var unitAmountRegex = /(\d+\.?\d*)+\s*([x|\/]\s*(\d+))?/;
function parseUnitAmountStr(unitStr) {
    var matches = unitAmountRegex.exec(unitStr);
    var unitAmount;
    if (matches) {
        unitAmount = parseFloat(matches[1]);
        if (matches[3]) {
            unitAmount *= parseFloat(matches[3]);
        }
    }
    return unitAmount;
}
exports.parseUnitAmountStr = parseUnitAmountStr;
function cleanUnitStr(unit) {
    // Handle some common alternative values for units
    // Also, get rid of case differences which 'convert-units' does not like
    switch (unit.toLowerCase()) {
        case 'gr':
        case 'gram':
        case 'grams':
            return 'g';
        case 'ml':
            return 'ml';
        case 'lt':
        case 'l':
        case 'litre':
        case 'liter':
            return 'l';
        case 'lb':
            return 'lb';
        case 'kg':
            return 'kg';
        case 'oz':
            return 'oz';
        case 'm':
            return 'm';
        case 'm^2':
        case 'm2':
            return 'm2';
    }
    // default to original
    return unit;
}
exports.cleanUnitStr = cleanUnitStr;
