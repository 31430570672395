import React, { useReducer, useState, useEffect, useRef } from 'react'
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  styled,
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconCopy from '@material-ui/icons/FileCopy'
import IconDelete from '@material-ui/icons/Delete'
import {
  Button,
  IconButton,
  Typography,
  InputBase,
  Chip,
} from '@material-ui/core'
import IconSave from '@material-ui/icons/Save'
import IconAdd from '@material-ui/icons/Add'
import { DatePicker } from '@material-ui/pickers'
import { writeReceiptToWorkbookFile } from '@grocery/common/dist/receipts'
import { CreatableListPicker } from '../../components/ListPicker'
import CreatablePicker from '../../components/CreatablePicker'
import {
  SubCategories,
  allSubCategories,
  allCategories,
  allStores,
  allUnits,
  allProductNames,
  allBrands,
  rowSuggestions,
} from '../receipts/data'
import { useApolloClient } from '../../hooks/useApolloClient'
import { ExtractQueryResultType } from '../../lib/typeHelpers'
import {
  SavedKeywordsQueryResult,
  SavedKeywordsQueryVariables,
} from '@grocery/graphql/dist/client/gen-types'
import { SavedKeywordsQuery } from '@grocery/graphql/dist/client/queries/SavedKeywords'
import MainMenuFab from '../../components/MainMenuFab'

function ccyFormat(num?: number) {
  return `${num !== undefined ? num.toFixed(2) : '?'}`
}

function subtotal(items: Row[]) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0)
}

interface Row {
  id: number
  product: string
  brand: string
  price: number
  savings: number
  unit: string
  quantity: number
  category: string
  subCategories: string[]
  notes: string
  suggestedSearch: string
}

const defaultRow = (product: string): Row => ({
  id: Math.random(),
  product,
  brand: '',
  price: 0,
  unit: '',
  quantity: 1,
  category: '',
  subCategories: [],
  savings: 0,
  notes: '',
  suggestedSearch: '',
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
      overflowX: 'auto',
      padding: theme.spacing(3),
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
    },
    tableRow: {
      height: '45px',
    },
    wideColumn: {
      padding: 0,
      minWidth: '160px',
    },
    column: {
      padding: 0,
    },
    narrowColumn: {
      padding: 0,
      maxWidth: '80px',
      minWidth: '50px',
    },
    rowInput: {
      height: '24px',
    },
  }),
)

const RTableRow = styled(TableRow)({
  height: '45px',
})

type Action =
  | { type: 'row.delete'; id: number }
  | { type: 'row.addNew' }
  | {
      type: 'row.update'
      id: number
      value: Partial<Row>
      autofillRow: boolean
    }
  | { type: 'row.duplicate'; id: number }
  | { type: 'save' }
  | { type: 'storeName'; storeName: string }
  | { type: 'gst'; gst: number }
  | { type: 'pst'; pst: number }
  | { type: 'transactionDate'; transactionDate: Date | null }

interface State {
  receiptItems: Row[]
  storeName: string
  transactionDate: Date
  gst: number
  pst: number
}

function defaultState(): State {
  return {
    receiptItems: [
      defaultRow(''),
      defaultRow(''),
      defaultRow(''),
      defaultRow(''),
    ],
    storeName: '',
    transactionDate: new Date(),
    gst: 0,
    pst: 0,
  }
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'row.update':
    case 'row.delete':
    case 'row.duplicate':
    case 'row.addNew':
      return {
        ...state,
        receiptItems: receiptItemReducer(state.receiptItems, action),
      }
    case 'storeName':
      return { ...state, storeName: action.storeName }
    case 'transactionDate':
      if (action.transactionDate == null) {
        // ignore
        return state
      }
      return { ...state, transactionDate: action.transactionDate }
    case 'gst':
      return { ...state, gst: action.gst }
    case 'pst':
      return { ...state, pst: action.pst }
    case 'save':
      const { storeName, transactionDate, receiptItems, gst, pst } = state
      writeReceiptToWorkbookFile({
        storeName,
        transactionDate,
        items: receiptItems,
        totals: { gst, pst },
      })
      return defaultState()
    default:
      return state
  }
}

const receiptItemReducer = (state: Row[], action: Action) => {
  switch (action.type) {
    case 'row.update':
      let newValue = action.value
      if (action.autofillRow && action.value.product) {
        const suggestion = rowSuggestions.find(
          (rs) => rs.name === action.value.product,
        )
        if (suggestion) {
          newValue.category = suggestion.category
          newValue.subCategories = [...suggestion.subCategories]
          newValue.unit = suggestion.unit
          newValue.suggestedSearch = suggestion.search
        }
      }
      return state.map((r) => (r.id === action.id ? { ...r, ...newValue } : r))
    case 'row.delete':
      return state.filter((r) => r.id !== action.id)
    case 'row.duplicate':
      const index = state.findIndex((r) => r.id === action.id)
      return [
        ...state.slice(0, index + 1),
        { ...state[index], id: Math.random() },
        ...state.slice(index + 1),
      ]
    case 'row.addNew':
      return [...state, defaultRow('')]
    default:
      return [...state]
  }
}

export default function ReceiptItemTable() {
  const [savedKeywords, setSavedKeywords] = useState<string[]>([])
  const client = useApolloClient()

  useEffect(() => {
    async function doFetch() {
      const { data, errors } = await client.query<
        ExtractQueryResultType<SavedKeywordsQueryResult>,
        SavedKeywordsQueryVariables
      >({ query: SavedKeywordsQuery, variables: {}, fetchPolicy: 'no-cache' })
      if (errors) {
        console.error(errors)
      }
      setSavedKeywords(data.savedKeywords)
    }
    doFetch()
  }, [client, setSavedKeywords])

  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, defaultState())
  const receipts = state.receiptItems

  const handleDateChange = (date: Date | null) =>
    dispatch({ type: 'transactionDate', transactionDate: date })

  const addRow = () => {
    dispatch({ type: 'row.addNew' })
  }

  const updateRow = (id: number, receipt: Partial<Row>) => {
    dispatch({
      type: 'row.update',
      id,
      value: receipt,
      autofillRow: receipt['product'] !== undefined,
    })
  }

  const duplicateRow = (id: number) => {
    dispatch({ type: 'row.duplicate', id })
  }

  const deleteRow = (id: number) => {
    dispatch({ type: 'row.delete', id })
  }

  const saveReceipt = () => {
    dispatch({ type: 'save' })
  }

  const setGST = (gst: number) => dispatch({ type: 'gst', gst })
  const setPST = (pst: number) => dispatch({ type: 'pst', pst })

  return (
    <>
      <b style={{ display: 'flex', flexDirection: 'row' }}>
        <MainMenuFab />
        <Typography variant="h1">Receipts</Typography>
      </b>
      <Paper className={classes.root}>
        <Typography style={{ gridColumn: '1/3' }}>Receipt Details</Typography>
        <CreatablePicker
          placeholder={'Store Name'}
          defaultValue={state.storeName}
          options={allStores}
          onChange={(store) => {
            dispatch({ type: 'storeName', storeName: store })
          }}
        />
        <DatePicker
          variant="inline"
          label="Transaction Date"
          inputVariant="outlined"
          value={state.transactionDate}
          onChange={handleDateChange}
        />
        <Table
          style={{ gridColumn: '1/3' }}
          size="small"
          aria-label="receipt items"
        >
          <TableHead>
            <RTableRow>
              <TableCell>Product</TableCell>
              <TableCell align="left">Brand</TableCell>
              <TableCell align="left">Qty.</TableCell>
              <TableCell align="left">Units</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Savings</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Sub-category</TableCell>
              <TableCell align="left">Search</TableCell>
              <TableCell align="left">Notes</TableCell>
              <TableCell />
            </RTableRow>
          </TableHead>
          <TableBody>
            {receipts.map((row, i) => (
              <RTableRow key={row.id}>
                <ReceiptRow
                  productNames={allProductNames}
                  categories={allCategories}
                  subCategories={allSubCategories}
                  suggestedSearches={savedKeywords}
                  brandNames={allBrands}
                  onChange={(id, changes) => updateRow(id, changes)}
                  onDelete={(id) => deleteRow(id)}
                  onDuplicate={(id) => duplicateRow(id)}
                  receipt={row}
                />
              </RTableRow>
            ))}
            <RTableRow>
              <TableCell style={{ border: 'none' }} align="right" colSpan={11}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={addRow}
                  startIcon={<IconAdd />}
                >
                  Add Row
                </Button>
              </TableCell>
            </RTableRow>
          </TableBody>
        </Table>
        <Table size="small">
          <TableHead>
            <RTableRow>
              <TableCell />
              <TableCell />
            </RTableRow>
          </TableHead>
          <TableBody>
            <RTableRow>
              <TableCell>
                <strong>Subtotal</strong>
              </TableCell>
              <TableCell>${ccyFormat(subtotal(receipts))}</TableCell>
            </RTableRow>
            <RTableRow>
              <TableCell>5% GST</TableCell>
              <TableCell>
                <CellInput
                  type="number"
                  value={state.gst}
                  onChange={(e) =>
                    setGST(Number.parseFloat(e.currentTarget.value))
                  }
                />
              </TableCell>
            </RTableRow>
            <RTableRow>
              <TableCell>7% PST</TableCell>
              <TableCell>
                <CellInput
                  type="number"
                  value={state.pst}
                  onChange={(e) =>
                    setPST(Number.parseFloat(e.currentTarget.value))
                  }
                />
              </TableCell>
            </RTableRow>
            <RTableRow>
              <TableCell>
                <strong>Total</strong>
              </TableCell>
              <TableCell>
                ${ccyFormat(state.gst + state.pst + subtotal(receipts))}
              </TableCell>
            </RTableRow>
          </TableBody>
        </Table>
        <b></b>
        <Button
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          onClick={saveReceipt}
          startIcon={<IconSave />}
        >
          Save
        </Button>
      </Paper>
    </>
  )
}

interface ReceiptRowProps {
  receipt: Row
  onChange: (id: number, changes: Partial<Row>) => void
  onDuplicate: (id: number) => void
  onDelete: (id: number) => void
  productNames: string[]
  brandNames: string[]
  categories: string[]
  subCategories: SubCategories
  suggestedSearches: string[]
}

const ReceiptRow = React.memo(
  function (props: ReceiptRowProps) {
    const classes = useStyles()
    const { onChange, onDuplicate, onDelete } = props
    const handleReceiptChange = (v: Partial<Row>) =>
      onChange(props.receipt.id, v)

    let subCategories: string[] = []
    if (props.receipt.category) {
      subCategories = props.subCategories[props.receipt.category]
    }

    const [focused, setFocused] = useState('')
    let inputRef: any = null
    useEffect(() => {
      if (inputRef !== null) {
        if (focused) {
          inputRef.focus()
        } else {
          inputRef.blur()
        }
      }
    }, [focused, inputRef])

    const [focused2, setFocused2] = useState('')
    const inputRef2 = useRef<HTMLInputElement>(null)
    useEffect(() => {
      if (inputRef2.current !== null) {
        if (focused2) {
          inputRef2.current.focus()
        } else {
          inputRef2.current.blur()
        }
      }
    }, [focused2])

    return (
      <>
        <TableCell
          className={classes.wideColumn}
          size="medium"
          onFocus={() => setFocused('product')}
          onBlur={() => setFocused('')}
        >
          {focused === 'product' ? (
            <CreatablePicker
              setRef={(ref) => {
                inputRef = ref
              }}
              defaultValue={props.receipt.product}
              options={props.productNames}
              onChange={(product) => {
                handleReceiptChange({ product })
              }}
            />
          ) : (
            <div tabIndex={0}>
              {props.receipt.product || (
                <Button color="secondary" size="small">
                  edit
                </Button>
              )}
            </div>
          )}
        </TableCell>
        <TableCell
          className={classes.wideColumn}
          align="left"
          size="small"
          onFocus={() => setFocused('brand')}
          onBlur={() => setFocused('')}
        >
          {focused === 'brand' ? (
            <CreatablePicker
              setRef={(ref) => {
                inputRef = ref
              }}
              defaultValue={props.receipt.brand}
              options={props.brandNames}
              onChange={(brand) => {
                handleReceiptChange({ brand })
              }}
            />
          ) : (
            <div tabIndex={0}>
              {props.receipt.brand || (
                <Button color="secondary" size="small">
                  edit
                </Button>
              )}
            </div>
          )}
        </TableCell>
        <TableCell
          className={classes.narrowColumn}
          size="small"
          align="center"
          onFocus={() => setFocused2('quantity')}
          onBlur={() => setFocused2('')}
        >
          {focused2 === 'quantity' ? (
            <CellInput
              inputRef={inputRef2}
              value={props.receipt.quantity}
              onChange={(e) =>
                handleReceiptChange({
                  quantity: Number.parseFloat(e.currentTarget.value),
                })
              }
              className={classes.rowInput}
              type="number"
              autoFocus
              onFocus={(e) => e.target.select()}
            />
          ) : (
            <div tabIndex={0}>{props.receipt.quantity}</div>
          )}
        </TableCell>
        <TableCell
          className={focused === 'unit' ? classes.column : classes.narrowColumn}
          size="small"
          align="center"
          onFocus={() => setFocused('unit')}
          onBlur={() => setFocused('')}
        >
          {focused === 'unit' ? (
            <CreatablePicker
              setRef={(ref) => {
                inputRef = ref
              }}
              defaultValue={props.receipt.unit}
              options={allUnits}
              onChange={(unit) => {
                handleReceiptChange({ unit })
              }}
            />
          ) : (
            <div tabIndex={0}>
              {props.receipt.unit.toUpperCase() || (
                <Button color="secondary" size="small">
                  edit
                </Button>
              )}
            </div>
          )}
        </TableCell>
        <TableCell
          className={classes.narrowColumn}
          size="small"
          align="center"
          onFocus={() => setFocused2('price')}
          onBlur={() => setFocused2('')}
        >
          {focused2 === 'price' ? (
            <CellInput
              inputRef={inputRef2}
              value={props.receipt.price}
              onChange={(e) =>
                handleReceiptChange({
                  price: Number.parseFloat(e.currentTarget.value),
                })
              }
              className={classes.rowInput}
              type="number"
            />
          ) : (
            <div tabIndex={0}>{props.receipt.price}</div>
          )}
        </TableCell>
        <TableCell
          className={classes.narrowColumn}
          size="small"
          align="center"
          onFocus={() => setFocused2('savings')}
          onBlur={() => setFocused2('')}
        >
          {focused2 === 'savings' ? (
            <CellInput
              inputRef={inputRef2}
              value={props.receipt.savings}
              onChange={(e) =>
                handleReceiptChange({
                  savings: Number.parseFloat(e.currentTarget.value),
                })
              }
              className={classes.rowInput}
              type="number"
            />
          ) : (
            <div tabIndex={0}>{props.receipt.savings}</div>
          )}
        </TableCell>
        <TableCell
          className={
            focused === 'category' ? classes.wideColumn : classes.column
          }
          align="left"
          onFocus={() => setFocused('category')}
          onBlur={() => setFocused('')}
        >
          {focused === 'category' ? (
            <CreatablePicker
              setRef={(ref) => {
                inputRef = ref
              }}
              defaultValue={props.receipt.category}
              options={props.categories}
              onChange={(category) => {
                handleReceiptChange({ category })
              }}
            />
          ) : (
            <div tabIndex={0}>
              {props.receipt.category || (
                <Button color="secondary" size="small">
                  edit
                </Button>
              )}
            </div>
          )}
        </TableCell>
        <TableCell
          className={
            focused === 'subCategories' ? classes.wideColumn : classes.column
          }
          align="left"
          onFocus={() => setFocused('subCategories')}
          onBlur={() => setFocused('')}
        >
          {focused === 'subCategories' ? (
            <CreatableListPicker
              setRef={(ref) => {
                inputRef = ref
              }}
              options={subCategories}
              value={props.receipt.subCategories}
              onChange={(v) => handleReceiptChange({ subCategories: v })}
              onCreate={(v) =>
                handleReceiptChange({ subCategories: v.selectedOptions })
              }
            />
          ) : (
            <div tabIndex={0}>
              {props.receipt.subCategories.length > 0 ? (
                props.receipt.subCategories.map((sc) => <Chip label={sc} />)
              ) : (
                <Button color="secondary" size="small">
                  edit
                </Button>
              )}
            </div>
          )}
        </TableCell>
        <TableCell
          className={
            focused === 'suggestedSearch' ? classes.wideColumn : classes.column
          }
          align="left"
          onFocus={() => setFocused('suggestedSearch')}
          onBlur={() => setFocused('')}
        >
          {focused === 'suggestedSearch' ? (
            <CreatablePicker
              setRef={(ref) => {
                inputRef = ref
              }}
              defaultValue={
                props.receipt.suggestedSearch || props.receipt.product
              }
              options={props.suggestedSearches}
              onChange={(search) => {
                handleReceiptChange({ suggestedSearch: search })
              }}
            />
          ) : (
            <div tabIndex={0}>
              {props.receipt.suggestedSearch || props.receipt.product || (
                <Button color="secondary" size="small">
                  edit
                </Button>
              )}
            </div>
          )}
        </TableCell>
        <TableCell className={classes.column} align="left">
          <CellInput
            value={props.receipt.notes}
            onChange={(e) =>
              handleReceiptChange({ notes: e.currentTarget.value })
            }
            className={classes.rowInput}
            type="text"
            style={{ width: '100%' }}
          />
        </TableCell>
        <TableCell padding="none" align="right">
          <IconButton
            tabIndex={-1}
            size="small"
            aria-label="Copy Row"
            onClick={() => onDuplicate(props.receipt.id)}
          >
            <IconCopy />
          </IconButton>
          <IconButton
            tabIndex={-1}
            size="small"
            aria-label="Delete Row"
            onClick={() => onDelete(props.receipt.id)}
          >
            <IconDelete />
          </IconButton>
        </TableCell>
      </>
    )
  },
  (a, b) =>
    a.receipt === b.receipt &&
    a.productNames === b.productNames &&
    a.suggestedSearches === b.suggestedSearches,
)

const CellInput = withStyles((theme: Theme) =>
  createStyles({
    root: {},
    input: {
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 14,
      width: '100%',
      padding: 'none',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)(InputBase)
