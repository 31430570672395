import React, { useState } from 'react'
import { useApolloClient } from '../../hooks/useApolloClient'
import Page from '../page'
import { useParams, useHistory } from 'react-router-dom'
import IconBack from '@material-ui/icons/ArrowBack'
import { IconButton, Typography, Divider, makeStyles, createStyles, Theme, Box, Switch, TextField } from '@material-ui/core'
import FilterPanel, { FilterPanelProps } from '../../components/FilterPanel'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { MutationDeleteFilterArgs, useGetFilterQuery, Unit, Maybe } from '@grocery/graphql/dist/client/gen-types'
import { DeleteFilter } from '@grocery/graphql/dist/client/queries/DeleteFilter'

const actions = [
    { icon: <SearchIcon />, name: 'Search' },
    { icon: <DeleteIcon />, name: 'Delete' },
    { icon: <SaveIcon />, name: 'Save' },
]

const useStyles = makeStyles((theme: Theme) => createStyles({
    speedDial: {
        position: 'absolute',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(2),
            right: theme.spacing(4),
        },
    },
}))

export default () => {
    const classes = useStyles()
    const client = useApolloClient()
    const params = useParams<{ keywords: string }>()
    const history = useHistory()
    const [alertEnabled, setAlertEnabled] = useState(false)
    const [open, setOpen] = React.useState(false)
    const { data, loading } = useGetFilterQuery({
        client,
        variables: {
            keywords: params.keywords,
        }
    })

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleDelete = async () => {
        const { errors } = await client.mutate<boolean, MutationDeleteFilterArgs>({
            variables: { keywords: params.keywords },
            mutation: DeleteFilter,
        })
        if (errors) {
            console.error(errors)
            return
        }
        history.push('/searches')
    }

    const Back = <IconButton onClick={() => {
        history.push('/searches')
    }}><IconBack />
    </IconButton>

    return <Page loading={loading} showHamburger={false} topBarContent={<>{Back}{params.keywords}</>}>
        <Alerting initialPrice={data?.getFilter?.goodPPUValueThreshold} unit={data?.getFilter?.unit} alertEnabled={alertEnabled} onToggleAlertEnabled={() => setAlertEnabled(!alertEnabled)} />
        <Divider variant="middle" />
        <Filtering config={data?.getFilter} />
        <Box style={{ position: 'relative', padding: '16px', paddingTop: '28px', marginTop: '8px' }}>
            <SpeedDial
                className={classes.speedDial}
                ariaLabel="Actions"
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="up"
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={async () => {
                            if (action.name === 'Search') {
                                history.push(`/search/${params.keywords}`)
                            }
                            if (action.name === 'Delete') {
                                handleDelete()
                            }
                            handleClose()
                        }}
                    />
                ))}
            </SpeedDial>
        </Box>
    </Page>
}


const sectionStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2),
    }
}))

const Section: React.FC<{}> = props => {
    const classes = sectionStyles()
    return <Box component="span" className={classes.root}>{props.children}</Box>
}

const rowStyles = makeStyles((theme: Theme) => createStyles({
    twoColumn: {
        display: 'grid',
        marginLeft: theme.spacing(2),
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: theme.spacing(7),
    },
    oneColumn: {
        marginLeft: theme.spacing(2),
    },
    picker: {
        height: '100%',
        width: '100%',
    },
    title: {
        fontSize: theme.typography.h4.fontSize,
        marginBottom: theme.spacing(2),
    }
}))

const Alerting: React.FC<{ unit?: Maybe<Unit>, initialPrice: Maybe<number>, alertEnabled: boolean, onToggleAlertEnabled: () => void }> = props => {
    const classes = rowStyles()
    let defaultPrice = props.initialPrice
    if (defaultPrice) {
        defaultPrice = defaultPrice * 0.75
    }
    return <Section>
        <Typography className={classes.title} variant="h2">Alerting</Typography>
        <Box className={classes.twoColumn}>
            <Typography>Alert me about products in this search</Typography>
            <Box style={{ marginLeft: 'auto' }}>
                {props.alertEnabled && <>
                    <TextField id="priceInput" label="Price per-unit" variant="outlined" InputProps={{
                        defaultValue: defaultPrice?.toFixed(2),
                        type: 'number',
                        startAdornment: <InputAdornment position="start">
                            $
                    </InputAdornment>,
                        endAdornment: <InputAdornment position="end"> {props.unit} </InputAdornment>,
                    }} />

                </>}
                <Switch
                    checked={props.alertEnabled}
                    onChange={props.onToggleAlertEnabled}
                    value="alertEnabled"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </Box>
        </Box>
    </Section>
}

const Filtering: React.FC<{ config?: FilterPanelProps['config'] }> = props => {
    const classes = rowStyles()
    return <Section>
        <Typography className={classes.title} variant="h2">Filtering</Typography>
        <Box className={classes.oneColumn}>
            <FilterPanel disabled config={{
                includes: [],
                excludes: [],
                excludeProductIDs: [],
                excludeStoreIDs: [],
                ...(props.config ?? {})
            }} />
        </Box>
    </Section >
}