import firebase from 'firebase/app'
import 'firebase/auth'

var config = {
  apiKey: 'AIzaSyCCNDVD7vBSawT6xtLtHaiTPhS87EeYo-o',
  authDomain: 'grocery-planner-137b6.firebaseapp.com',
  databaseURL: 'https://grocery-planner-137b6.firebaseio.com',
  projectId: 'grocery-planner-137b6',
  storageBucket: '',
  messagingSenderId: '427225255499',
  appId: '1:427225255499:web:6969c35e02ef48cbdfc7ce',
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

export default firebase
