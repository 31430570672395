"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var xslx_parser_1 = require("./xslx-parser");
var zip_1 = require("./zip");
var xlsx_1 = require("xlsx");
/**
 * Gets all the receipts in a zip file
 *
 * @param zip a path to a zip containing .xlsx receipts in Mus Receipt Format (MuRF)
 */
function getReceipts(zip) {
    return __awaiter(this, void 0, void 0, function () {
        var receipts;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    receipts = [];
                    return [4 /*yield*/, zip_1.streamZipFiles(zip, '.xlsx', function (w, d) { return __awaiter(_this, void 0, void 0, function () {
                            var receipt;
                            return __generator(this, function (_a) {
                                receipt = xslx_parser_1.parseReceipt(w, d);
                                if (receipt != null) {
                                    receipts.push(receipt);
                                }
                                return [2 /*return*/];
                            });
                        }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/, receipts];
            }
        });
    });
}
exports.default = getReceipts;
function receiptRowToArray(row) {
    return [row.product, row.brand, row.quantity, row.unit, row.price, row.savings, row.category, row.subCategories.join(','), row.notes, row.suggestedSearch];
}
function writeReceiptToWorkbookFile(data) {
    var date = data.transactionDate;
    var year = date.getFullYear().toString().substr(-2);
    var month = (1 + date.getMonth()).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    console.log("writing data");
    console.dir(data);
    /* create a new blank workbook */
    var wb = xlsx_1.utils.book_new();
    {
        var ws_name = "Items";
        var wsRows = data.items.map(receiptRowToArray);
        /* make worksheet */
        var ws_data = __spreadArrays([
            ["Product", "Brand", "Quantity", "Units", "Price", "Savings", "Category", "Sub-category", "Notes", "Suggested Search"]
        ], wsRows);
        var ws = xlsx_1.utils.aoa_to_sheet(ws_data);
        /* Add the worksheet to the workbook */
        xlsx_1.utils.book_append_sheet(wb, ws, ws_name);
    }
    {
        var ws_name = "Totals";
        var _a = receiptTotalsReducer(data.totals.gst, data.totals.pst, data.items), subtotal = _a.subtotal, total = _a.total, totalSavings = _a.totalSavings;
        /* make worksheet */
        var ws_data = [
            ["Subtotal", "5% GST", "7% PST", "Total", "Total Savings"],
            [subtotal, data.totals.gst, data.totals.pst, total, totalSavings]
        ];
        var ws = xlsx_1.utils.aoa_to_sheet(ws_data);
        ws['A2'].f = 'SUM(Items!E2:E99)';
        ws['D2'].f = 'SUM(A2:C2)';
        ws['E2'].f = 'SUM(Items!F2:F99)';
        /* Add the worksheet to the workbook */
        xlsx_1.utils.book_append_sheet(wb, ws, ws_name);
    }
    xlsx_1.writeFile(wb, month + "-" + day + "-" + year + "_" + data.storeName.split(' ').join('-') + ".xlsx");
}
exports.writeReceiptToWorkbookFile = writeReceiptToWorkbookFile;
function receiptTotalsReducer(gst, pst, array) {
    return array.reduce(function (acc, cur) {
        return {
            subtotal: acc.subtotal + cur.price,
            total: acc.total + cur.price,
            totalSavings: acc.totalSavings + cur.savings,
        };
    }, { total: gst + pst, subtotal: 0, totalSavings: 0 });
}
