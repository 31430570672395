import React from 'react'
import { makeStyles, createStyles, Theme, Paper, Fab, CircularProgress } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import CancelIcon from '@material-ui/icons/Undo'
import SaveIcon from '@material-ui/icons/Save'

const useStyles = makeStyles((theme: Theme) => createStyles({
    hideFab: {
        backgroundColor: theme.palette.primary.main,
    },
    saveFab: {
        backgroundColor: theme.palette.primary.main,
    },
    cancelFab: {
        backgroundColor: theme.palette.secondary.main,
    },
    filterFab: {
        position: 'absolute',
        backgroundColor: theme.palette.primary.light,
        bottom: theme.spacing(4),
        right: theme.spacing(6),
    }
}))

export interface ControlPanelProps {
    dirty: boolean
    saving: boolean
    showReset?: boolean
    onSave: () => void
    onDismiss: () => void
    onReset: () => void
}

export const ControlPanel: React.FC<ControlPanelProps> = props => {
    const classes = useStyles({})
    const { dirty, saving, showReset, onSave, onDismiss, onReset } = props
    return <Paper style={{ position: 'relative', padding: '16px', paddingTop: '28px', marginTop: '8px' }}>
        {!dirty && !saving && <Fab aria-label="hide settings" className={classes.hideFab} style={{ position: 'absolute', top: '-28px', left: 'calc(50% - 28px)' }} onClick={onDismiss}>
            <ArrowDownwardIcon />
        </Fab>}
        {saving && <Fab aria-label="saving filter" className={classes.hideFab} style={{ position: 'absolute', top: '-28px', left: 'calc(50% - 28px)' }} onClick={onDismiss}>
            <CircularProgress />
        </Fab>}
        {dirty && <>
            <Fab aria-label="save changes" className={classes.saveFab} style={{ position: 'absolute', top: '-28px', left: 'calc(50% - 28px)' }} onClick={onSave}>
                <SaveIcon />
            </Fab>

            {(showReset ?? true) && <Fab aria-label="cancel changes" size="small" className={classes.cancelFab} style={{ position: 'absolute', top: '-20px', left: 'calc(50% - 80px)' }} onClick={onReset}>
                <CancelIcon />
            </Fab>}
        </>}
        {props.children}
    </Paper>
}