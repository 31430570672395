import React, { useState } from 'react'
import { Typography, createStyles, makeStyles, Theme, Container, Box, Drawer, Paper, CircularProgress } from '@material-ui/core'
import SplashImage from './grocery-splash.jpg'
import ErrorSnackBar from '../components/ErrorSnackBar'
import MainAppBar from '../components/MainAppBar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MainMenu from '../components/MainMenu'
import { ApolloError } from 'apollo-boost'

const useStyles = makeStyles((theme: Theme) => createStyles({
    primaryProgressIcon: {
        color: 'white'
    },
    container: {
        flex: 1,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
        flexFlow: 'column',
    },
    loadingModal: {
        position: 'absolute',
        width: 140,
        height: 150,
        // backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
    mainProgress: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
    },
    chromeDark: {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${SplashImage})`,
        backgroundColor: `${theme.palette.primary.light}`,
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        overflow: 'auto',
    },
    chrome: {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${SplashImage})`,
        backgroundColor: `${theme.palette.primary.light}`,
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        overflow: 'auto',
    },
}))

export const Background: React.FC<{ dark: boolean }> = props => {
    const classes = useStyles()
    return <Box className={props.dark ? classes.chromeDark : classes.chrome}>
        {props.children}
    </Box >
}

export const Page: React.FC<{ error?: ApolloError, loading: boolean, showHamburger?: boolean, topBarContent: JSX.Element | string | JSX.Element[], bottomContainerContent?: JSX.Element | string }> = props => {
    const classes = useStyles({})
    const [mainMenuDrawerOpen, setMainMenuOpen] = useState(false)
    const [modalStyle] = React.useState(getModalStyle)

    let appBarButton
    if (props.showHamburger ?? true) {
        appBarButton = <IconButton
            edge="start"
            color="inherit"
            aria-label="Open Main Menu"
            onClick={() => setMainMenuOpen(!mainMenuDrawerOpen)}
        >
            <MenuIcon />
        </IconButton>
    }

    return <Background dark={true}>
        <ErrorSnackBar error={props.error?.message ?? ''} />
        <Drawer
            anchor="left"
            open={mainMenuDrawerOpen}
            onClose={() => setMainMenuOpen(false)}
        >
            <MainMenu />
        </Drawer>
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AppBar appBarButton={appBarButton} mainElement={props.topBarContent} />
            {!props.loading && <Container maxWidth="md" className={classes.container}>
                <Paper square={false} style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    {props.children}
                </Paper>
                {props.bottomContainerContent ?? <></>}
            </Container>}
            {props.loading && <Box style={modalStyle} className={classes.loadingModal}>
                <Box className={classes.mainProgress}>
                    <CircularProgress className={classes.primaryProgressIcon} size={180} />
                </Box>
            </Box>}
        </div>
    </Background >
}

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

function AppBar(props: { appBarButton?: JSX.Element, mainElement: JSX.Element | string | JSX.Element[] }) {
    let primaryElement = props.mainElement
    if (typeof props.mainElement == 'string') {
        primaryElement = <Typography>{props.mainElement}</Typography>
    }
    return <MainAppBar>
        {props.appBarButton ?? ''}
        {primaryElement}
    </MainAppBar>
}

export default Page