import React from 'react'

import CreatableSelect from 'react-select/creatable'
import Select, { ValueType } from 'react-select'

interface ListPickerOption {
  label: string
  value: string
}

export interface ListPickerProps {
  options: string[]
  value?: string[]
  onChange(values: string[]): void
}

export const ListPicker = (props: ListPickerProps) => {
  const [options, value] = loadProps(props)
  const onChange = (opt: ValueType<ListPickerOption>) =>
    handleChange(opt, props.onChange)

  return <Select isMulti options={options} value={value} onChange={onChange} />
}

export interface CreatableListPickerProps extends ListPickerProps {
  onCreate(result: { newValue: string; selectedOptions: string[] }): void
  setRef?: (ref: any) => void
}

export const CreatableListPicker = (props: CreatableListPickerProps) => {
  const [options, value] = loadProps(props)
  const onChange = (opt: ValueType<ListPickerOption>) =>
    handleChange(opt, props.onChange)
  const onCreate = (val: string) => {
    props.onCreate({
      newValue: val,
      selectedOptions: value.map((o) => o.value).concat(val),
    })
  }

  return (
    <CreatableSelect
      ref={(ref) => {
        props.setRef && props.setRef(ref)
      }}
      isMulti
      options={options}
      value={value}
      onChange={onChange}
      onCreateOption={onCreate}
    />
  )
}

function loadProps(
  props: ListPickerProps,
): [ListPickerOption[], ListPickerOption[]] {
  const options: ListPickerOption[] = []
  if (props.options) {
    props.options.forEach((a) => {
      return options.push({ label: a, value: a })
    })
  }

  let value: ListPickerOption[] = []
  if (props.value) {
    value = props.value.map((v) => {
      return { label: v, value: v }
    })
  }

  return [options, value]
}

function handleChange(
  opt: ValueType<ListPickerOption>,
  cb: (values: string[]) => void,
) {
  if (Array.isArray(opt)) {
    cb(opt.map((o) => o.value))
  } else if (opt) {
    cb([(opt as ListPickerOption).value])
  } else {
    cb([])
  }
}
