import React from 'react'
import FilterPanel, { FilterPanelProps } from '../../components/FilterPanel'
import { ControlPanelProps, ControlPanel } from './ControlPanel'

type FilterControlProps = {
    config?: FilterPanelProps['config']
    onConfigChanged: (config: FilterPanelProps['config']) => void
} & ControlPanelProps

export const FilteringControlPanel: React.FC<FilterControlProps> = props => {
    const { onConfigChanged } = props
    let config = props.config ?? {
        includes: [],
        excludes: [],
        excludeProductIDs: [],
        excludeStoreIDs: [],
    }
    return <ControlPanel {...props}>
        <FilterPanel
            onConfigChanged={onConfigChanged}
            config={config} />
    </ControlPanel>
}
